import React from "react";
import { Link } from "react-router-dom";
import servicesData from "./ServicesData"; // Ensure the correct path to the servicesData file

const ServicesList = () => (
  <div className="services-list">
    <h1>Our Services</h1>
    <ul className="services-links">
      {servicesData.map((service) => (
        <li key={service.id}>
          <Link to={`/services/${service.id}`} className="service-link">
            {service.title}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default ServicesList;
