import React, { useState, useEffect } from "react";
import "../styles/Blog.css";
import BlogPage from "../Components/Blog/BlogPage"; // Component for displaying posts
import Pagination from "../Components/Blog/Pagination"; // Component for pagination

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5;
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch blogs on mount
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch("https://avatarstudios.in/wp-json/wp/v2/posts");
        if (!response.ok) throw new Error("Failed to fetch blogs.");
        const data = await response.json();

        // Map data to match our required structure
        const mappedPosts = data.map((post) => ({
          id: post.id,
          title: post.title.rendered, // Title is under `title.rendered`
          content: post.content.rendered, // Content is under `content.rendered`
          image: post.featured_media_url || `https://via.placeholder.com/150?text=Blog+${post.id}`, // Placeholder image if none provided
        }));
        setPosts(mappedPosts);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  // Filter posts based on the search query
  const filteredPosts = posts.filter((post) =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Paginate posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="blog-container">
      <header className="blog-header">
        <h1>Our Blog</h1>
        <input
          type="text"
          placeholder="Search blogs..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="blog-search"
        />
      </header>
      <BlogPage posts={currentPosts} />
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={filteredPosts.length}
        paginate={paginate}
      />
    </div>
  );
};

export default Blogs;
