import React, { useState } from "react";
import "./ConsultationForm.css";

const ConsultationForm = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    country: "USA",
    requirement: "Graphic Design",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form submitted successfully!");
    console.log(formData);
  };

  return (
    <div className="bg-white sm:py-8 py-16 px-6">
      <div className="text-center mb-8">
        <h2 className="consultationform-heading">Your Success Starts Here</h2>
        <p className="consultationform-subheading">
          Book A Free{" "}
          <span className="consultationform-highlight">Consultation Today!</span>
        </p>
      </div>
      <form
        onSubmit={handleSubmit}
        className="consultationform-form"
      >
        <div className="md:col-span-1">
          <label className="consultationform-label">Username</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Enter username"
            required
            className="consultationform-input"
          />
        </div>
        <div className="md:col-span-1">
          <label className="consultationform-label">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter email"
            required
            className="consultationform-input"
          />
        </div>
        <div className="md:col-span-1">
          <label className="consultationform-label">Phone</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Enter phone"
            required
            className="consultationform-input"
          />
        </div>
        <div className="md:col-span-1">
          <label className="consultationform-label">Country</label>
          <select
            name="country"
            value={formData.country}
            onChange={handleChange}
            className="consultationform-select"
          >
            <option value="USA">USA</option>
            <option value="India">India</option>
            <option value="UK">UK</option>
            <option value="Australia">Australia</option>
          </select>
        </div>
        <div className="md:col-span-2">
          <label className="consultationform-label">Requirements*</label>
          <select
            name="requirement"
            value={formData.requirement}
            onChange={handleChange}
            className="consultationform-select"
          >
            <option value="Graphic Design">Graphic Design</option>
            <option value="Web Development">Web Development</option>
            <option value="SEO Optimization">SEO Optimization</option>
            <option value="Digital Marketing">Digital Marketing</option>
          </select>
        </div>
        <div className="md:col-span-6 text-center">
          <p className="consultationform-privacy">
            We Respect Your Privacy.{" "}
            <a href="/policy" className="consultationform-highlight">
              Read Our Policy
            </a>.
          </p>
          <button
            type="submit"
            className="consultationform-button"
          >
            Submit Now{" "}
            <span>&#8594;</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConsultationForm;
