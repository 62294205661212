import React from "react";
import "../styles/ContactUsPage.css";
import { FaLinkedin, FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import LetsTalk from "../Components/LetsTalk/LetsTalk";
import ClientCarousel from "../Components/ClientCarousel/ClientCarousel";
import LocationPage from "../Components/Location/LocationPage";
import Newsletter from "../Components/Newsletter/Newsletter";

const ContactUsPage = () => {
  return (
    <div className="contactus-page-container">
      {/* Header Section */}
      <LetsTalk />
      <ClientCarousel />
      <LocationPage />
<Newsletter />
    </div>
  );
};

export default ContactUsPage;
