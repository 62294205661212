import React, { useState, useEffect } from "react";
import "./BookACall.css"; // Import the CSS file for styling
import { RiTimeZoneFill } from "react-icons/ri";
import { BsArrowUpRightCircle } from "react-icons/bs";

const BookACall = () => {
    const [currentTime, setCurrentTime] = useState("");
    useEffect(() => {
        const updateTime = () => {
          const now = new Date();
          const timeString = now.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          setCurrentTime(timeString);
        };
    
        updateTime();
        const intervalId = setInterval(updateTime, 60000); // Update time every minute
    
        return () => clearInterval(intervalId); // Cleanup interval on unmount
      }, []);


  return (
    <div className="bookacall-container">
      {/* Left Side Content */}
      <div className="bookacall-content">
        <h2>Talk to Our Team Now!</h2>
        <ul>
          <li>Free consultation</li>
          <li>Demo Website Audit</li>
          <li>Get Quotation for interested service needs</li>
          <li>Ask your doubts to us</li>
        </ul>
        {/* <button className="bookacall-contact-button">Use the contact form</button> */}
        <button className="bg-yellow-400 text-black color-white font-semibold py-2 px-6 rounded-full hover:bg-yellow-500 transition duration-300 flex items-center">
        Use the contact form <BsArrowUpRightCircle className="color-white"/>
              </button>
      </div>

      {/* Right Side Calendar */}
      <div className="calendar-section">
        <h3>Select a Day</h3>
        <div className="calendar">
          <div className="month-nav">
            <span className="calendar-section-arrow">{"<"}</span>
            <span className="month">January 2025</span>
            <span className="calendar-section-arrow">{">"}</span>
          </div>
          <div className="weekdays">
            <span>MON</span>
            <span>TUE</span>
            <span>WED</span>
            <span>THU</span>
            <span>FRI</span>
            <span>SAT</span>
            <span>SUN</span>
          </div>
          <div className="dates">
            {[...Array(31)].map((_, i) => (
              <div
                key={i}
                className={`date ${i >= 14 && i <= 25 ? "available" : ""}`}
              >
                {i + 1}
              </div>
            ))}
          </div>
        </div>
        <div className="timezone">
            <span className="flex gap-2"><RiTimeZoneFill /> Time zone</span>
          <span>India Standard Time ({currentTime})</span>
        </div>
      </div>
    </div>
  );
};

export default BookACall;
