import React, { useState } from "react";
import "./JobPositionsMobile.css";
import { FaArrowRight } from "react-icons/fa6";

// Static data for positions
const positions = [
  {
    title: "Full-Stack Developers",
    tags: ["Tartu", "Full-time", "Engineering"],
    description:
      "Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team.",
  },
  {
    title: "Application developer (React Native)",
    tags: ["Tartu", "Full-time", "Engineering"],
    description:
      "Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team.",
  },
  {
    title: "Senior Product Designer",
    tags: ["Hybrid", "Tallinn", "Full-time", "Design"],
    description:
      "Since 2019 we've worked on 30+ major projects from 8 different industries.",
  },
];

// Generate categories dynamically
const categories = (() => {
  const allTags = positions.flatMap((position) => position.tags);
  const uniqueTags = Array.from(new Set(allTags));
  const categoryCounts = uniqueTags.reduce((acc, tag) => {
    acc[tag] = allTags.filter((t) => t === tag).length;
    return acc;
  }, {});
  return [
    { name: "All Positions", count: positions.length },
    ...Object.entries(categoryCounts).map(([name, count]) => ({ name, count })),
  ];
})();

const JobPositionsMobile = () => {
  const [selectedCategory, setSelectedCategory] = useState("All Positions");
  const [expandedPosition, setExpandedPosition] = useState(null);

  // Filter positions based on the selected category
  const filteredPositions =
    selectedCategory === "All Positions"
      ? positions
      : positions.filter((position) =>
          position.tags.includes(selectedCategory)
        );

  // Handle accordion toggle
  const toggleAccordion = (index) => {
    setExpandedPosition(expandedPosition === index ? null : index);
  };

  return (
    <div className="jobpositionmobile-container">
      {/* Categories Accordion */}
      <div className="jobpositionmobile-sidebar">
        {categories.map((category) => (
          <div
            key={category.name}
            className={`jobpositionmobile-category ${
              selectedCategory === category.name ? "active" : ""
            }`}
            onClick={() => setSelectedCategory(category.name)}
          >
            {category.name} ({category.count})
          </div>
        ))}
      </div>

      {/* Positions Accordion */}
      <div className="jobpositionmobile-list">
        {filteredPositions.map((position, index) => (
          <div key={index} className="jobpositionmobile-card">
            <div
              className="jobpositionmobile-header"
              onClick={() => toggleAccordion(index)}
            >
              <h3>{position.title}</h3>
              <span>{expandedPosition === index ? "-" : "+"}</span>
            </div>
            {expandedPosition === index && (
              <div className="jobpositionmobile-body">
                <div className="jobpositionmobile-tags">
                  {position.tags.map((tag, idx) => (
                    <span key={idx} className="jobpositionmobile-tag">
                      {tag}
                    </span>
                  ))}
                </div>
                <p>{position.description}</p>
                <button className="jobpositionmobile-cta-button">
                  See positions <FaArrowRight />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobPositionsMobile;
