import React, { useState } from "react";
import "./MobileMenu.css"; // Create a CSS file for styling
import { FaBarsStaggered } from "react-icons/fa6";
import logo1 from "../../Assets/icons/logo.png";
import { FaBox, FaPaintBrush } from "react-icons/fa";
import NavbarMobile from "../../NavbarMobile";
import { FaAngleDown } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";


const MobileMenu = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (menu) => {
    setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu));
  };

  const toggleMenuVisibility = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const menuSections = [
    {
      title: "Creative",
      items: [
        { label: "Branding Solutions", href: "/branding-solutions", description: "Learn about your users",hasIcon: true,icon: <FaPaintBrush />,},
        { label: "Packaging Designing", href: "/packaging-designing", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Infographic Design", href: "/infographic-design", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Illustrations Recreation", href: "/illustrations-recreation", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Social Media ADS", href: "/social-media-ads", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Illustrations Recreation", href: "/illustrations-recreation", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Social Media ADS", href: "/social-media-ads", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Branding Solutions", href: "/branding-solutions", description: "Learn about your users",hasIcon: true,icon: <FaPaintBrush />,},
        { label: "Packaging Designing", href: "/packaging-designing", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Infographic Design", href: "/infographic-design", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Illustrations Recreation", href: "/illustrations-recreation", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Social Media ADS", href: "/social-media-ads", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Illustrations Recreation", href: "/illustrations-recreation", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Social Media ADS", href: "/social-media-ads", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },

      ],
    },
    { title: "Technology", items: ["Web Development", "Mobile Apps", "AI Solutions"] },
    { title: "Video Production", items: ["Corporate Videos", "Promotional Videos"] },
    { title: "Digital Marketing", items: ["SEO", "Social Media", "Email Campaigns"] },
    { title: "2D Animation", items: ["Explainer Videos", "Character Animation"] },
    { title: "3D Animation", items: ["Product Demos", "3D Modeling"] },
    { title: "Printing", items: ["Business Cards", "Flyers", "Posters"] },
    { title: "Photography", items: ["Product Shoots", "Event Photography"] },
    { title: "Videography", items: ["Weddings", "Corporate Events"] },
  ];

  return (
    <div className="mobile-menu">
      <div className="menu-header">
      <img loading="lazy" src={logo1} alt="logo1" className='img-fluid'></img>
        <button className="menu-toggle" onClick={toggleMenuVisibility}>
          <FaBarsStaggered className="menu-list" />
        </button>
      </div>
      <NavbarMobile />
      {isMenuOpen && (
        <div className="menu-content">
          {menuSections.map((section, index) => (
            <div key={index} className="menu-section">
              <div
                className={activeMenu === section.title ? "menu-active" : "menu-title"}
                onClick={() => toggleMenu(section.title)}
              >
                {section.title}
                <span>{activeMenu === section.title ? <FaMinus style={{color:"#000"}}/>
 : <FaAngleDown style={{color:"#ffbb00"}}/>
                }</span>
              </div>
              {activeMenu === section.title && (
                <div className="sub-menu">
                  {section.items.map((item, idx) => (
    <div key={idx} className="sub-menu-item">
      <div className="item-icon">{item.icon}</div>
      <span>{item.label}</span>
    </div>
  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
