import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.css";
import { CgProfile } from "react-icons/cg";
import useIsMobile from '../useIsMobile';
import { FaCircleUser } from "react-icons/fa6";
import profile from "../../Assets/icons/testimonial.png"


const testimonials = [
  {
    name: "Basawaraj A",
    role: "2 years ago",
    rating: 5,
    text: "We have been working together with Digiworq team since 5 years. They are professional team with huge experience in creative, multimedia and web solutions. We are quite fortunate that we got ourselves associated with them. We are very very happy and we keep recommending them with with all our friends and colleagues.",
    image: profile,
  },
  {
    name: "Ayyappa",
    role: "2 years ago",
    rating: 5,
    text: "I have to say that Digiworq Marketing & Technology Solutions is one of the best companies I have ever worked with. The design and print services they offered for our menu card was completed within hours with great quality and professional service. I would highly recommend this company for all your needs.",
    image: profile,
  },
  {
    name: "Sathish GR",
    role: "2 years ago",
    rating: 5,
    text: "Digiworq is reliable, they are very innovative. They help startups and well established companies to promote their products/services. They have a good team of designers.",
    image: profile,
  },
  {
    name: "Euguru",
    role: "2 years ago",
    rating: 5,
    text: "Excellent Services👌👌👌. Mr RK is a thorough professional and an expert in his profession. Our company's websites are being designed by him. This is the best website design agency and branding company I’ve ever seen. I strongly recommend Digiworq...👍👍👍",
    image: profile,
  },
  {
    name: "Asvini Kuma",
    role: "3 years ago",
    rating: 5,
    text: "I am happy to share my appreciation of the work done by Digiworq on my BIC talk youtube post production project. They were very responsive,time bound and I feel it is good value for me.",
    image: profile,
  },
];

const TestimonialCard = ({ name, role, rating, text, image }) => {

  const isMobile = useIsMobile(); // Use the custom hook here

  return (
    <div className="testimonial-card">
      <div className="card-header">
        {image ? (<img src={image} alt={name} className="testimonial-image" />) : (<FaCircleUser />)}

        <div>
          <h3>{name}</h3>
          <p className="role">{role}</p>
        </div>
      </div>
      <div className="rating">
        {"★".repeat(rating)}{"☆".repeat(5 - rating)}
      </div>
      <p className="testimonial-text">{text}</p>
    </div>
  );
};

const Testimonials = () => {
  const isMobile = useIsMobile(); // Use the custom hook here

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        arrows: false, // Hide navigation arrows
        centerPadding: "20px",
        customPaging: () => (
          <div className="custom-dot"></div> // Custom dot element
        ),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              centerPadding: "10px",
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              centerPadding: "20px",
            },
          },
        ],
      };
      

  return (
    <div className="testimonials-container">
      {isMobile ? ("") : (<div className="testimonials-header">
        <div className="testimonial-header-left">
          <h2 className="text-3xl font-normal"> Our Happy Clientele <br /> Diaries</h2>
        </div>
        <div className="testimonial-header-right">
          <h3>Testimonials</h3>
          <p>We are happy and motivated to do more than what's expected…</p>
        </div>
      </div>)}
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} {...testimonial} />
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
