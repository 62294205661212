import React from 'react';
import './Navbar.css';
import { MdOutlineShoppingCart } from "react-icons/md";
import { LiaSearchSolid } from "react-icons/lia";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";

const NavbarMobile = () => {
  return (

<nav className="navbar">
  {/* <div className="navbar-left Desktop-nav">
    <div  className="logo">
    <a href="/">Follow Us On</a>

     <a href="/"><FaWhatsapp /></a>
     <a href="/"><FaFacebookF /></a>
     <a href="/"><FaLinkedin /></a>
     <a href="/"><FaInstagram /></a>
     <a href="/"><FaYoutube /></a>
     </div>
  </div>
  <div className="navbar-left Mobile-nav">
    <span className='mob-space'>
     <a href="tel:+91 96114 89001"><FiPhone /></a>
     <a href="/" className="logo">+91 96114 89001</a>
     </span>
  </div> */}
  <div className="navbarmobile-center">
    <ul className="navmob-links">
      <li>
        <a href="/products">Home</a>
      </li>
      <li>
        <a href="/AboutUs">About Us</a>
      </li>
      <li>
        <a href="/OurWorks">Our Works</a>
      </li>
      <li>
        <a href="/Career">Careers</a>
      </li>
      {/* <li>
      <a href="/" className="logo">+91 96114 89001</a>
      </li> */}
    </ul>
  </div>
  {/* <div className="navbar-right">
  <a href="/account" className="user-icon">
      <span className="">Search</span>
<span><LiaSearchSolid /></span>
    </a>
    <a href="/cart" className="cart-icon">
      <span className="">Cart</span>
      <span className=""><MdOutlineShoppingCart /></span>
      </a>
    
  </div> */}
</nav>
);
};

export default NavbarMobile;