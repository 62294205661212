import React, { useState } from "react";
import "../styles/OurWorks.css";
import worksimg1 from "../Assets/OurWorks/worksimg1.png"
import worksimg2 from "../Assets/OurWorks/worksimg2.png"
import worksimg3 from "../Assets/OurWorks/worksimg3.png"
import worksimg4 from "../Assets/OurWorks/worksimg4.png"
import worksimg5 from "../Assets/OurWorks/worksimg5.png"
import worksimg6 from "../Assets/OurWorks/worksimg6.png"
import { BsArrowUpRightCircle } from "react-icons/bs";

const projectData = [
  { id: 1, image: worksimg1, title: "Project 1", description: "Branding", category: "branding" },
  { id: 2, image: worksimg2, title: "Project 2", description: "Web Design", category: "web" },
  { id: 3, image: worksimg3, title: "Project 3", description: "UI/UX", category: "uiux" },
  { id: 4, image: worksimg4, title: "Project 4", description: "Event Branding", category: "event-branding" },
  { id: 5, image: worksimg5, title: "Project 5", description: "2D Animation", category: "animation" },
  { id: 6, image: worksimg6, title: "Project 6", description: "3D Animation", category: "animation" },
  { id: 7, image: "/images/project7.jpg", title: "Project 7", description: "Web Development", category: "web" },
  { id: 8, image: "/images/project8.jpg", title: "Project 8", description: "Branding", category: "branding" },
];

const OurWorks = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  // Filter the projects based on the selected category
  const filteredProjects = selectedCategory === "all"
    ? projectData
    : projectData.filter((project) => project.category === selectedCategory);

  return (
    <div className="our-works">

              {/* Hero Section */}
                    {/* Hero Section */}
                    <div className="hero">
              <div className="About-banner-container">
              <div className="aboutus-overlay"></div> {/* Updated overlay class name */}
              <div className="About-banner-content">
                <h1>Masterpieces In The<br />
                  <span className="About-highlight">Making</span>.
                </h1>
              </div>
            </div>
              </div>
        
              <section className="our-works-hero-section">
                <h2>Our Finest Work</h2>
                <p>Empowering Brands with Global, User-Centric Design</p>
                <button className="m-auto bg-yellow-400 text-black color-white font-semibold py-2 px-6 rounded-full hover:bg-yellow-500 transition duration-300 flex items-center">Contact us<BsArrowUpRightCircle className="color-white"/></button>
              </section>
      {/* Tabs Section */}
      <div className="our-works-tabs">
      <a
  href="#all"
  className={selectedCategory === "all" ? "active" : ""}
  onClick={() => setSelectedCategory("all")}
>
  All
</a>
        <a href="#branding" onClick={() => setSelectedCategory("branding")}>Branding</a>
        <a href="#web" onClick={() => setSelectedCategory("web")}>Web</a>
        <a href="#uiux" onClick={() => setSelectedCategory("uiux")}>UI/UX</a>
        <a href="#animation" onClick={() => setSelectedCategory("animation")}>Animation</a>
        <a href="#event-branding" onClick={() => setSelectedCategory("event-branding")}>Event Branding</a>
      </div>

      {/* Projects Grid */}
      <section className="our-works-projects-grid">
        {filteredProjects.map((project) => (
          <div key={project.id} className="our-works-project-card">
            <img
              src={project.image}
              alt={project.title}
              className="our-works-project-image"
            />
            <div className="our-works-project-info">
              <h4>{project.title}</h4>
              <p>{project.description}</p>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default OurWorks;
