import React from "react";
import "./Footer.css";
import footerlogo from "../../Assets/icons/footerlogo.png"
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { CiTextAlignLeft } from "react-icons/ci";


const FooterSection = ({ title, children }) => (
  <div className="footer-section">
    <h3>{title}</h3>
    {children}
  </div>
);

const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="footer-logo">
        <img src="/logo.png" alt="Digiworq Logo" className="footer-logo-img" />
        <p className="footer-tagline">Refining your vision</p>
        <p className="footer-description">
          When do they work well, and when do they on us and finally, when do we actually need how can we avoid them.
        </p>
      </div> */}

      <div className="footer-links">

      <div className="footer-section">
      <div className="footer-logo">
        <img src={footerlogo} alt="Digiworq Logo" className="footer-logo-img" />
        <p className="footer-description">
        Welcome to Digiworq, where the experts of the digital marketing and technology realm work together for absolute success. As a leading branding and advertising agency, we specialise in crafting customised strategies, campaign plans, branding ideologies and other services to push your brand’s path towards stunning results.
        </p>
      </div>
</div>
        {/* <FooterSection title="Quick Links" style={{textAlign:"center"}}> */}
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li>Home</li>
            <li>About</li>
            <li>Services</li>
            <li>Portfolio</li>
            <li>Blog</li>
            <li>Career</li>
            <li>Contact</li>
            <li>Privacy Policy</li>
            <li>Terms & Conditions</li>
          </ul>
          </div>
        {/* </FooterSection> */}

        <FooterSection title="Our Services" style={{width:"150px", textAlign:"center"}}>
          <ul>
            <li>Creative</li>
            <li>Technology</li>
            <li>Video Production</li>
            <li>Digital Marketing</li>
            <li>2D Animation</li>
            <li>3D Animation</li>
            <li>Printing Solution</li>
            <li>Photography</li>
            <li>Videography</li>
          </ul>
        </FooterSection>

        <FooterSection title="Connect With Us" className="connect-text"  style={{textAlign:"left"}}>
          {/* <p>INDIA</p> */}
          <p className="flex items-top"><span style={{paddingTop: "7px", paddingRight: "10px"}}><FaLocationDot style={{color: "#f3b218"}}/>
          </span><span  style={{textAlign:"left"}}>4/20, 1st B Main Road, 1st N Block, Near Ganapathi Temple Extension, Rajajinagar, Bengaluru, Karnataka 560010</span></p>
          <p className="flex items-center"><span style={{paddingRight: "10px"}}><MdEmail  style={{color: "#f3b218"}}/>
          </span><span>Email: info@digiworq.com</span></p>
          <p className="flex items-center"><span style={{paddingRight: "5px"}}><FaPhone  style={{color: "#f3b218"}}/>
          </span><span style={{paddingRight: "5px"}}>+91 96114 87333</span> <span style={{paddingRight: "5px"}}>|</span> <span style={{paddingRight: "5px"}}><FaPhone  style={{color: "#f3b218"}}/>
          </span><span>+91 96114 89001</span></p>
        </FooterSection>

        <FooterSection title="Follow Us On"  style={{textAlign:"left"}}>
          <div className="footer-social-icons">
            <span><FaFacebookF /></span>
            <span><FaYoutube /></span>
            <span><FaLinkedinIn /></span>
            <span><FaXTwitter /></span>
          </div>
        </FooterSection>
      </div>

      <div className="footer-bottom flex">
        <p className="footer-left">Copyright © 2025. digiworq.com</p>
        <p className="footer-right">Privacy Policy | Disclaimer | Cookie Policy | Terms & Conditions</p>
      </div>
    </footer>
  );
};

export default Footer;
