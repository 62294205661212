import React from "react";
import "./OurStory.css";
import ProgressBar from "../ProgressBar/ProgressBar";

const OurStory = () => {
  return (
    <div className="our-story-container">
          <div className="about-stats-grid">
  <div>
    <h2 className="stat-number">01+</h2>
    <ProgressBar progress={20} />
    <p className="stat-label">Creative <br />Strategies</p>
  </div>
  <div>
    <h2 className="stat-number">10+</h2>
    <ProgressBar progress={50} />
    <p className="stat-label">Years of <br />Experience</p>
  </div>
  <div>
    <h2 className="stat-number">25K</h2>
    <ProgressBar progress={75} />
    <p className="stat-label">Happy <br />Clients</p>
  </div>
  <div>
    <h2 className="stat-number">200+</h2>
    <ProgressBar progress={40} />
    <p className="stat-label">Projects <br />Cleared</p>
    {/* <ProgressBar progress={200} /> */}
  </div>
</div>

      <div className="story-content">
        <h2>Digital Studio</h2>
        <h3>Our Story</h3>
        <p>
        Founded in Bangalore, Digiworq arose from a deep-seated passion for digital innovation. With over a decade of experience, we empower businesses with bespoke marketing strategies designed to help them flourish in an ever-evolving digital landscape.
        </p>
        <p>Our slogan, "Redefining Your Vision," encapsulates our commitment to being the foremost experts in digital marketing and technology solutions in Bangalore. Our team comprises seasoned professionals with diverse backgrounds in digital marketing, design, technology, and analytics. This multidisciplinary approach enables us to tackle challenges from multiple angles.
</p>
        <p>
        From brand development to performance marketing, we provide a comprehensive suite of services designed to elevate every aspect of your digital presence.
        </p>
      </div>
    </div>
  );
};

export default OurStory;
