import React, { useState, useEffect } from "react";
import "./DropdownMenu.css";
import { FaArrowRight } from "react-icons/fa";

const DropdownMenu = ({ title, subtitle, items, image, imageAlt }) => {
    const [hoveredItem, setHoveredItem] = useState(null);

    useEffect(() => {
      // Set the first item as active when the dropdown is displayed
      setHoveredItem(items[0]);
    }, [items]);
    const chunkedItems = [];
    for (let i = 0; i < items.length; i += 5) {
      chunkedItems.push(items.slice(i, i + 5));
    }
  return (
    <div className="dropdown">
      <div className="dropdown-header">
        <h3 className="flex">{title}  <FaArrowRight style={{color:"#ffbb00", paddingLeft:"20px", fontSize:"25px", width: "50px"}}/>        </h3>
        <h3>{hoveredItem?.label || subtitle}</h3>
      </div>
      <div className="dropdown-content dropdown-grid">
      {chunkedItems.map((column, columnIndex) => (
        <div className="dropdown-column"  key={columnIndex}>
          <ul>
            {column.map((item, index) => (
              <li
              key={index}
              onMouseEnter={() => setHoveredItem(item)}
              onMouseLeave={() => setHoveredItem(null)}
              className={hoveredItem === item ? "active-item" : ""} // Add active class
            >
              <a
                href={item.href}
                className="dropdown-item flex items-center space-x-2"
              >
                {/* Icon */}
                <span className="dropdown-icon sub-item">{item.icon}</span>
                {/* Title and Description */}
                <div className="flex flex-col">
                  <span>{item.label}</span>
                  <p className="text-sm text-gray-500">{item.description}</p>
                </div>
              </a>
            </li>
            ))}
          </ul>
        </div>
      ))}
        <div className="dropdown-column image-column">
                  <img src={hoveredItem?.image || image} alt={hoveredItem?.imageAlt || imageAlt} />
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
