import React from 'react';
import './StayFocus.css'; // Include the styles in a separate CSS file
import stayfocus from "../../Assets/icons/stayfocus.png"
import { BsArrowRightCircle } from "react-icons/bs";

const StayFocus = () => {
  const features = [
    {
      title: 'Innovative Solutions',
      description: 'A cloud application, or cloud app, is a software program.',
      link: '#',
    },
    {
      title: 'Global Reach',
      description: 'A cloud application, or cloud app, is a software program.',
      link: '#',
    },
    {
      title: 'Client-Centric Approach',
      description: 'A cloud application, or cloud app, is a software program.',
      link: '#',
    },
    {
      title: 'Sustainability Initiatives',
      description: 'A cloud application, or cloud app, is a software program.',
      link: '#',
    },
  ];

  return (
    <div className="stayfocus-container">
      <h4 className="stayfocus-subtitle">WHERE CLARITY MEETS CREATIVITY</h4>
      <h2 className="stayfocus-title">
        Stay <span className="highlight">Focused.</span> Build Better.
      </h2>
      <div className="stayfocus-features">
        {features.map((feature, index) => (
          <div className="feature-card" key={index}>
            <div className="feature-icon">
              <img
                src={stayfocus} // Replace with the correct path
                alt={`${feature.title} Icon`}
              />
            </div>
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
            <a href={feature.link} className="feature-link">
              Learn More <BsArrowRightCircle />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StayFocus;
