import React from 'react';
import '../styles/Career.css';
import "../Components/MeetMaster/MeetMaster.css"
import meetmaster from "../Assets/about/meetmaster.png"
import { IoIosArrowRoundDown } from "react-icons/io";
import CareerImageGrid from '../Components/CareerImageGrid/CareerImageGrid';
import CareerSteps from '../Components/CareerSteps/CareerSteps';
import JobPositions from '../Components/JobPositions/JobPositions';
import ApplicationForm from '../Components/ApplicationForm/ApplicationForm';
import PlaceToWork from '../Components/PlaceToWork/PlaceToWork';
import FollowUsOn from '../Components/FollowUsOn/FollowUsOn';
import Cgrid from '../Components/CareerImageGrid/Cgrid';
import JobPositionsMobile from '../Components/JobPositions/JobPositionsMobile';
import useIsMobile from '../Components/useIsMobile';

const Career = () => {
    const isMobile = useIsMobile(); // Use the custom hook here

    const positions = [
        {
          title: "Full-Stack Developers",
          tags: ["Tartu", "Full-time", "Engineering"],
          description:
            "Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team.",
        },
        {
          title: "Application developer (React Native)",
          tags: ["Tartu", "Full-time", "Engineering"],
          description:
            "Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team.",
        },
        {
          title: "Senior Product Designer",
          tags: ["Hybrid", "Tallinn", "Full-time", "Design"],
          description:
            "Since 2019 we've worked on 30+ major projects from 8 different industries.",
        },
      ];

      const allTags = positions.flatMap((position) => position.tags);
  const uniqueTags = Array.from(new Set(allTags));

  // Calculate count for each unique tag
  const categoryCounts = uniqueTags.reduce((acc, tag) => {
    acc[tag] = allTags.filter((t) => t === tag).length;
    return acc;
  }, {});
      
      const categories = [
        { name: "All Positions", count: 3 },
        { name: "Engineering", count: 7 },
        { name: "Product", count: 3 },
        { name: "Design", count: 1 },
        { name: "Operation", count: 4 },
        { name: "Marketing", count: 2 },
      ];

  return (
    <div className="career">
                            <div className="hero">
              <div className="About-banner-container">
              <div className="aboutus-overlay"></div> {/* Updated overlay class name */}
              <div className="About-banner-content">
              <h1>Join Our Team To <br />Shape <span className='about-highlight'>The Future Of Design</span></h1>
              </div>
            </div>
              </div>
              <section className="meet-master-container">
      <div className="meet-master-content">
        <div className="text-container">
          <h2>Join The Team</h2>
          <p>
            Our team is always looking for new and innovative ways to assist our clients in achieving their goals. We believe in always being ahead of the curve and staying ahead of the competition. Our services include social media marketing, search engine optimization, email marketing, and more.
          </p>
          <p>
            We also offer a wide range of web design and development services. We are a one-stop-shop for all your digital marketing needs. So whether you are looking to revamp your website or want to start a social media campaign, we can help you achieve your goals.
          </p>
          <p>
            If you are looking for a reliable digital marketing agency in Bangalore that can help you take your business to the next level, then look no further than Digiworq.
          </p>
          <button className="bg-yellow-400 text-white color-white font-semibold py-2 px-6 rounded-full hover:bg-yellow-500 transition duration-300 flex items-center">
                  Open Positions <IoIosArrowRoundDown />
                </button>
        </div>
        <div className="image-container">
          <img 
            src={meetmaster} 
            alt="R. Krishnan, Founder & CEO" 
            className="founder-image" 
          />
        </div>
      </div>
    </section>
    {/* <CareerImageGrid /> */}
    <CareerSteps />
    <PlaceToWork />
    {isMobile ? <JobPositionsMobile /> : <JobPositions positions={positions} categories={categories} />}
    <FollowUsOn />
    <ApplicationForm />
    {/* <CareerImageGrid /> */}
      {/* <CareerHeader />
      <CareerSteps />
      <JobPositions />
      <SocialMedia />
      <ApplicationForm /> */}
    </div>
  );
};

export default Career;
