import React, { useState } from "react";
import "./Newsletter.css"; // Import a CSS file for styling
import { LuCircleArrowOutUpRight } from "react-icons/lu";

const Newsletter = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      alert("Please enter a valid email address.");
      return;
    }

    const response = await fetch("/newsletter_signup.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    if (response.ok) {
      alert("Thank you for subscribing!");
      setEmail(""); // Reset email input
    } else {
      alert("There was an error while subscribing.");
    }
  };

  return (
    <div className="Newsletter">
      <div className="Newsletter-container">
        {/* Left Section: Subscription Form */}
        <div className="Newsletter-subscription">
          <h2 className="Newsletter-title">
            Subscribe to our newsletter to stay in touch with the latest.
          </h2>
          <form className="Newsletter-form" onSubmit={handleSubmit}>
            <label htmlFor="email" className="Newsletter-label">
              Email<span className="Newsletter-required">*</span>
            </label>
            <div className="Newsletter-input-container">
              <input
                type="email"
                id="email"
                placeholder="Please, enter a valid email"
                className="Newsletter-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="Newsletter-button">
                <span>
                  <LuCircleArrowOutUpRight />
                </span>
              </button>
            </div>
          </form>
        </div>

        {/* Right Section: Contact Info */}
        <div className="Newsletter-contact">
          <div className="Newsletter-phone">
            <div className="Newsletter-phone-icon">📞</div>
            <div>
              <p className="Newsletter-phone-title">COMPANY PHONES</p>
              <p>+91 96114 89001</p>
              <p>+91 96114 87333</p>
            </div>
          </div>
          <div className="Newsletter-email">
            <div className="Newsletter-email-icon">💬</div>
            <div>
              <p className="Newsletter-email-title">EMAILS</p>
              <p>info@digiworq.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
