// MeetMaster.js
import React from 'react';
import './MeetMaster.css'; // Include styles
import meetmaster from "../../Assets/about//meetmaster.png"

const MeetMaster = () => {
  return (
    <section className="meet-master-container">
      <div className="meet-master-content">
        <div className="image-container">
          <img 
            src={meetmaster} 
            alt="R. Krishnan, Founder & CEO" 
            className="founder-image" 
          />
        </div>
        <div className="text-container">
          <h2>Meet the Master Crafters</h2>
          <p className="description">
            "We are a dynamic team of trendsetters, innovators, marketers, designers, storytellers, strategists, and tech enthusiasts, all driven by a shared passion for creating impactful narratives and enriching personal journeys."
          </p>
          <h3 className="founder-name">R. Krishnan Founder & CEO</h3>
          <p className="details">
          Digiworq, typically meets all the needs that a company would expect from a marketing and technology solutions company. More than just a business, we are your partners in future-proofing your brand!
          </p>
          <p className="details">
          We truly recognise the transformative power of storytelling and creativity along with the use of technology for progressive growth of businesses online. Our diverse team of specialists collaborates seamlessly, merging technology with artistry to craft impactful strategies tailored to each client’s unique objectives.
          </p>
          <p className="details">
          We take pride in our client-centric approach, ensuring that we thoroughly understand the goals and challenges associated with every project we undertake. As we continue to expand our horizons, we remain dedicated to pushing boundaries, embracing emerging trends, and delivering measurable results.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MeetMaster;
