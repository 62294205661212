import React from 'react';
import "./MainCarousel.css"
import HeroSection from '../HeroSection';
const MainCarousel = () => {
  return (

<div className="banner">
  <HeroSection />
</div>
);
};

export default MainCarousel;