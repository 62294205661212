import React from 'react';
import '../styles/AboutUs.css';
import OurStory from '../Components/OurStory/OurStory';
import ClientCarousel from '../Components/ClientCarousel/ClientCarousel';
import MeetMaster from '../Components/MeetMaster/MeetMaster';
import team1 from "../Assets/about/team1.png";
import team2 from "../Assets/about/team2.png";
import team3 from "../Assets/about/team3.png";
import team4 from "../Assets/about/team4.png";
import CraftingSection from '../Components/CraftingSection/CraftingSection';
import ConsultationForm from '../Components/ConsultationForm/ConsultationForm';
import VideoSection from '../Components/VideoSection/VideoSection';
import ScrollToTopButton from '../Components/ScrollToTopButton/ScrollToTopButton';

// Reusable Components
const SectionHeader = ({ title, subtitle }) => (
  <div className="section-header">
    <h2>{title}</h2>
    <p>{subtitle}</p>
  </div>
);

const TeamCard = ({ image, name, designation }) => (
  <div className="team-card">
    <img src={image} alt={name} className="team-image" />
    <h4>{name}</h4>
    <p>{designation}</p>
  </div>
);



const InfoCard = ({ image, title, description }) => (
  <div className="info-card">
    <img src={image} alt={title} className="info-image" />
    <h4>{title}</h4>
    <p>{description}</p>
  </div>
);

const AboutUs = () => {
  const teamMembers = [
    { image: team1, name: 'Team Member 1', designation: 'Designation' },
    { image: team2, name: 'Team Member 2', designation: 'Designation' },
    { image: team3, name: 'Team Member 3', designation: 'Designation' },
    { image: team4, name: 'Team Member 4', designation: 'Designation' },
    { image: '/images/team5.jpg', name: 'Team Member 5', designation: 'Designation' },
    { image: '/images/team1.jpg', name: 'Team Member 1', designation: 'Designation' },
    { image: '/images/team2.jpg', name: 'Team Member 2', designation: 'Designation' },
    { image: '/images/team3.jpg', name: 'Team Member 3', designation: 'Designation' },

  ];

  //   const infoSections = [
  //     {
  //       image: craftingimage1,
  //       title: 'Crafting Connections',
  //       description: 'Beyond the surface, we deliver excellence in design and execution.',
  //     },
  //     {
  //       image: craftingimage2,
  //       title: 'Innovative Solutions',
  //       description: 'Shaping the future with creativity and expertise.',
  //     },
  //   ];

  return (
    <div className="about-us">
      {/* Hero Section */}
      <div className="hero">
        <div className="About-banner-container">
          <div className="aboutus-overlay"></div> {/* Updated overlay class name */}
          <div className="About-banner-content">
            <h1>
              <span className="About-highlight">Your Brand’s </span> Digital Futures, <br />
              Surfed <span className="About-highlight">Here…</span>.
            </h1>
          </div>
        </div>
      </div>
      <div>
       
        <p className="about-title">
          <span className="about-content-highlight">Digiworq</span> is a digital marketing agency in <span className="about-content-highlight">Bangalore</span> Our passionate team assists
          clients in achieving their <span className="about-content-highlight">business goals</span> through creative design and innovative strategies.
        </p>
      </div>
      <div className='aboutus-body'>
        {/*Our Story */}
        <OurStory />
        {/*Client Carousel */}
        <ClientCarousel />
        <MeetMaster />

        {/* Leadership Section */}
        <SectionHeader title="Leadership Team" subtitle="Meet the master crafters behind our success." />
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <TeamCard key={index} {...member} />
          ))}
        </div>

        {/* Info Section */}
        <div className='about-crafting'>
          <h2 style={{ textAlign: "center" }} >Crafting Connections, beyond the surface</h2>
          <h4>The pulse of our tribe, uncovered</h4>
          <CraftingSection />
        </div>
        <VideoSection />

        {/* <div className="info-grid">
        {infoSections.map((info, index) => (
          <InfoCard key={index} {...info} />
        ))}
      </div> */}

        {/* Contact Section */}
        <ConsultationForm />
        <ScrollToTopButton />
      </div>
    </div>
  );
};

export default AboutUs;
