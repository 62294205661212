import React, { useState } from "react";
import "./WorkGalleryCarousel.css";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
const WorkGalleryCarousel = ({ projects, title, filters }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("All");

  // Filter projects
  const filteredProjects =
    selectedFilter === "All"
      ? projects
      : projects.filter((project) => project.category === selectedFilter);

  const itemsToShow = 3; // Number of items visible per row
  const totalItems = filteredProjects.length;

  const handleNext = () => {
    if (currentIndex + itemsToShow < totalItems) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  // Calculate the number of dots
  const numOfDots = Math.ceil(totalItems / itemsToShow);

  return (
    <div className="workgallerycarousel">
                <h2 className="workgallerycarousel-title">
          {title.main} <br /><span>{title.highlight}</span> {title.sub}
        </h2>
      <div className="workgallerycarousel-header">

        <div className="workgallerycarousel-filters">
          {filters.map((filter, index) => (
            <button
              key={index}
              className={`filter-button ${
                selectedFilter === filter ? "active" : ""
              }`}
              onClick={() => {
                setSelectedFilter(filter);
                setCurrentIndex(0); // Reset carousel when filter changes
              }}
            >
              {filter}
            </button>
          ))}
        </div>
        <div className="workgallerycarousel-controls">
          <button
            className="prev"
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
           <FaAngleLeft />
          </button>
          <button
            className="next"
            onClick={handleNext}
            disabled={currentIndex + itemsToShow >= totalItems}
          >
           <FaAngleRight />
           </button>
        </div>
      </div>
      <div className="workgallerycarousel-wrapper">
        <div
          className="workgallerycarousel-inner"
          style={{
            transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)`,
            width: `${(filteredProjects.length / itemsToShow) * 100}%`,
          }}
        >
          {filteredProjects.map((project, index) => (
            <div
              className="workgallerycarousel-item"
              key={index}
              style={{
                flex: `0 0 ${100 / itemsToShow}%`,
              }}
            >
              <div className="workgallerycarousel-card">
                <img
                  src={project.image}
                  alt={project.title}
                  className="workgallerycarousel-image"
                />
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
            {/* Dot Design */}
            <div className="workgallerycarousel-dots">
        {Array.from({ length: numOfDots }).map((_, index) => (
          <button
            key={index}
            className={`dot ${currentIndex === index * itemsToShow ? "active" : ""}`}
            onClick={() => setCurrentIndex(index * itemsToShow)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default WorkGalleryCarousel;
