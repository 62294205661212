import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import servicesData from "./ServicesData"; // Ensure this file contains the services data or adjust the path accordingly
import SubservicesBanner from "../Components/SubservicesBanner/SubservicesBanner";
import CeoQuote from "../Components/CeoQuote/CeoQuote";
import SubBranding from "../Components/SubBranding/SubBranding";
import InnerSectionBanner from "../Components/InnerSectionBanner/InnerSectionBanner";
import SubServicesCarousel from "../Components/SubServicesCarousel/SubServicesCarousel";
import WorkGalleryCarousel from "../Components/WorkGalleryCarousel/WorkGalleryCarousel";
import BrandingPackageCarousel from "../Components/BrandingPackageCarousel/BrandingPackageCarousel";
import Testimonials from '../Components/Testimonials/Testimonials';
import ScrollToTopButton from "../Components/ScrollToTopButton/ScrollToTopButton";

const ServicePage = ({ id }) => {
  const service = servicesData.find((s) => s.id === id);

  if (!service) {
    return (
      <div className="not-found">
        <h1>Service Not Found</h1>
        <Link to="/" className="back-link">
          Back to Services
        </Link>
      </div>
    );
  }

  const { title, description, keywords, banner, branding, serviceDetails } = service;

  return (
    <div className="service-page">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <SubservicesBanner servicesData={service} />
      <CeoQuote quoteData={service} />
      <SubBranding brandingSteps={service.brandingSteps} />
      <InnerSectionBanner bannerData={service} />
      <SubServicesCarousel services={service.services} leftContent={service.leftContent} />
      <WorkGalleryCarousel projects={service.projects} title={service.Workgallerytitle} filters={service.filters} />
      <BrandingPackageCarousel packages={service.packages} />
      <Testimonials />
      {/* Service Details Section */}
      {/* <section className="service-details">
        {serviceDetails.sections.map((section, index) => (
          <div key={index} className="service-section">
            <div className="service-content">
              <h3>{section.heading}</h3>
              <p>{section.content}</p>
            </div>
            <img src={section.image} alt={section.heading} className="service-image" />
          </div>
        ))}
      </section>

      <Link to="/" className="back-link">
        Back to Services
      </Link> */}
      <ScrollToTopButton />
    </div>
  );
};

export default ServicePage;
