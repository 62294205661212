import React, { useState } from "react";
import Slider from "react-slick";
import Solution1 from "../../Assets/solutions/solution1.png";
import Solution2 from "../../Assets/solutions/Graphic.png";
import Solution3 from "../../Assets/solutions/MobileApp.png";
import Solution4 from "../../Assets/solutions/UIUX.png";
import Solution5 from "../../Assets/solutions/packaging.png";
import './SolutionSlide.css';  // Import the external CSS file
import useIsMobile from '../useIsMobile';


// Reusable Accordion Component for Mobile
const SolutionAccordion = ({ tabs, activeTab, onTabChange }) => {
    const [expandedTab, setExpandedTab] = useState(activeTab);

    const handleTabClick = (tab) => {
      setExpandedTab(expandedTab === tab ? null : tab);
      onTabChange(tab);
    };
  
    return (
      <div className="accordion-container">
        {tabs.map((tab) => (
          <div key={tab} className="accordion-item">
            {/* Accordion Header */}
            <button
              className={`accordion-header ${expandedTab === tab ? "active" : ""}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </button>
            {/* Accordion Content */}
            {expandedTab === tab && (
              <div className="accordion-content">
                <p>Explore our {tab} solutions.</p>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

// Reusable Tab Component
const Tab = ({ label, activeTab, onClick }) => (
  <button
    onClick={onClick}
    className={`tab-button ${activeTab === label ? "active" : ""}`}
  >
    {label}
  </button>
);

// Reusable Slide Component
const Slide = ({ image, title, description }) => (
  <div className="slider-item p-4">
    <div className="relative rounded-md overflow-hidden shadow-lg">
      <img src={image} alt={title} className="slider-image" />
      <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4">
        <h3 className="text-white font-bold text-lg">{title}</h3>
        <p className="text-gray-200 text-sm mt-2">{description}</p>
      </div>
    </div>
  </div>
);

// Main Component
const SolutionsSlide = () => {
  const [activeTab, setActiveTab] = useState("Creative");
  const tabs = ["Creative", "Technology", "Video Production", "Digital Marketing", "2D Animation", "3D Animation", "Printing", "Photography", "Videography "];
  const isMobile = useIsMobile(); // Use the custom hook here

  const slides = [
    {
      category: "Creative",
      items: [
        { image: Solution2, title: "Creative Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },
        { image: Solution2, title: "Branding Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },

      ],
      fixedItem: { image: Solution1, title: "Creative Solutions", description: "Our innovative approaches integrate creativity and technology to enhance brand storytelling and boost brand visibility." }
    },
    
    {
      category: "Technology",
     items: [
        { image: Solution2, title: "Creative Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },
        { image: Solution2, title: "Branding Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },

      ],
      fixedItem: { image: Solution1, title: "Tech Solutions", description: "Our specialised use of advanced technology solutions are aimed at improving operational productivity and user engagement." }
    },
    {
      category: "Video Production",
      items: [
        { image: Solution2, title: "Creative Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },
        { image: Solution2, title: "Branding Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },

      ],
      fixedItem: { image: Solution1, title: "Video Production", description: "Our exceptional video content development engages viewers and effectively conveys your brand's message with clarity." }
    },
    {
      category: "Digital Marketing",
    items: [
        { image: Solution2, title: "Creative Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },
        { image: Solution2, title: "Branding Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },

      ],
      fixedItem: { image: Solution1, title: "Digital Marketing", description: "Our analytics-driven marketing tactics are aimed at maximising online visibility and attracting targeted traffic to your business." }
    },
    {
      category: "2D Animation",
    items: [
        { image: Solution2, title: "Creative Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },
        { image: Solution2, title: "Branding Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },

      ],
      fixedItem: { image: Solution1, title: "2D Animation", description: "Our vibrant 2D animations clarify intricate ideas and enrich visual storytelling for effective communication to your audience." }
    },
    {
      category: "3D Animation",
    items: [
        { image: Solution2, title: "Creative Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },
        { image: Solution2, title: "Branding Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },

      ],
      fixedItem: { image: Solution1, title: "3D Animation", description: "Our stunning and photorealistic 3D animation clips bring life to your world of imagination setting an immersive visual experience." }
    },
    {
      category: "Printing Services",
     items: [
        { image: Solution2, title: "Creative Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },
        { image: Solution2, title: "Branding Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },

      ],
      fixedItem: { image: Solution1, title: "Printing Services", description: "Our high-quality printing options for promotional materials and advertising requirements that embody your brand’s character." }
    },
    {
      category: "Photography Services",
      items: [
        { image: Solution2, title: "Creative Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },
        { image: Solution2, title: "Branding Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },

      ],
      fixedItem: { image: Solution1, title: "Photography Services", description: "Our expert photography captures the true spirit of your brand through striking visuals and genuine representation." }
    },
    {
      category: "Videography Services",
    items: [
        { image: Solution2, title: "Creative Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },
        { image: Solution2, title: "Branding Solutions", description: "Custom branding solutions to elevate your business." },
        { image: Solution3, title: "Graphic Design", description: "High-quality graphic design services." },
        { image: Solution4, title: "UI/UX Design", description: "Innovative UI/UX design for apps and websites." },
        { image: Solution5, title: "Mobile App Design", description: "Create stunning mobile app experiences." },

      ],
      fixedItem: { image: Solution1, title: "Videography Services", description: "Our professional videography chronicles events and crafts compelling stories to resonate with your absolute audience." }
    },
  ];

  const activeSlides = slides.find((slide) => slide.category === activeTab)?.items || [];
  const fixedItem = slides.find((slide) => slide.category === activeTab)?.fixedItem || {};

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    customPaging: () => (
        <div className="custom-dot"></div> // Custom dot element
      ),
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div className="solutions-container py-16 px-6">
      <h2 className="text-3xl font-normal py-8">Our Marketing & Technology Solutions That Work Wonders For You.</h2>
{/* Render Tabs or Accordion based on screen size */}
      {isMobile ? (""
        // <SolutionAccordion tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />
      ) : (
        <div className="tab-container">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      )}
      {isMobile ? ("" ) : (
      <div className="flex-container">
        {/* First Fixed Item */}
        <div className="fixed-item">
          <div className="relative rounded-md overflow-hidden shadow-lg">
            <img src={fixedItem.image || Solution1} alt={fixedItem.title || "Fixed Item"} className="w-full h-60 object-cover" />
            <div className="fixed-item-content">
              <h3>{fixedItem.title || "Fixed Item"}</h3>
              <p>{fixedItem.description || "Description of fixed item."}</p>
              <button>View More</button>
            </div>
          </div>
        </div>
        {/* Horizontal Slider */}
        <div className="slider-container" style={{ width: activeTab === "Creative" ? "75%" : "100%" }}>
          <Slider {...sliderSettings}>
            {activeSlides.map((slide, index) => (
              <Slide
                key={index}
                image={slide.image}
                title={slide.title}
                description={slide.description}
              />
            ))}
          </Slider>
        </div>
      </div>)}
    </div>
  );
};

export default SolutionsSlide;
