import React from "react";
import "./SubBranding.css";

const SubBranding = ({ brandingSteps }) => {
    if (!brandingSteps || !Array.isArray(brandingSteps)) {
        console.error("brandingSteps is not defined or not an array");
        return null; // Or some fallback UI
      }
  return (
    <div className="subbranding-container">
      <h2 className="subbranding-title">
        Our <br/><span>Branding Process</span>
      </h2>
      <div className="subbranding-steps">
        {brandingSteps.map((step, index) => (
          <div key={index} className="subbranding-step">
            <div className="subbranding-step-header">
              <div className="subbranding-step-number">{`0${index + 1}`}</div>
              <div>
                <h3 className="subbranding-step-title">{step.stepstitle}</h3>
                <p className="subbranding-step-duration">{step.stepsduration}</p>
              </div>
            </div>
            <div className="subbranding-step-body">
              <p className="subbranding-step-description">{step.stepsdescription}</p>
              <div className="subbranding-step-deliverables">
                <strong>Deliverables:</strong>
                <div className="subbranding-deliverables-list">
                  {step.stepsdeliverables.map((item, idx) => (
                    <span key={idx} className="subbranding-deliverable">
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubBranding;
