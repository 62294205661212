import React from "react";
import "./CeoQuote.css";

const CeoQuote = ({ quoteData }) => {
  const { ceoimage, ceoname, ceotitle, ceoquote } = quoteData;

  return (
    <div className="ceo-quote-container">
      <div className="ceo-quote-content">
        <div className="ceo-quote-left">
          <img src={ceoimage} alt={ceoname} className="ceo-quote-image" />
          <div className="ceo-quote-details">
            <h2 className="ceo-quote-name">{ceoname}</h2>
            <h3 className="ceo-quote-title">{ceotitle}</h3>
          </div>
        </div>
        <div className="ceo-quote-right">
          <div className="ceo-quote-line"></div>
          <h2 className="ceo-quote-text">{ceoquote}</h2>
        </div>
      </div>
    </div>
  );
};

export default CeoQuote;
