import React from "react";
import Slider from "react-slick";
import client1 from "../../Assets/clientcarousel/client01.png";
import client2 from "../../Assets/clientcarousel/client02.png";
import client3 from "../../Assets/clientcarousel/client03.png";
import client4 from "../../Assets/clientcarousel/client04.png";
import client5 from "../../Assets/clientcarousel/client05.png";
import client6 from "../../Assets/clientcarousel/client06.png";
import client7 from "../../Assets/clientcarousel/client07.png";
import "./ClientCarousel.css"; // Import external styles

const ClientCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, // Disables navigation arrows
    customPaging: () => <div className="custom-dot"></div>, // Custom dot element
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const logos = [
    { src: client1, alt: "Atlassian" },
    { src: client2, alt: "Deel" },
    { src: client3, alt: "WeightWatchers" },
    { src: client4, alt: "Plaid" },
    { src: client5, alt: "IDEO" },
    { src: client6, alt: "Microsoft" },
    { src: client7, alt: "Riot Games" },
  ];

  return (
    <div className="clientcarousel-container">
      <div className="clientcarousel-header">
        <h2 className="clientcarousel-title">
          Our Clients Trust Us Worldwide For <br />
          Our Excellent Works In
          <span className="clientcarousel-highlight"> Web Development</span>
        </h2>
      </div>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="clientcarousel-slide">
            <img src={logo.src} alt={logo.alt} className="clientcarousel-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ClientCarousel;
