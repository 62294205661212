import '../App.css';
import '../styles/global.css';
import MainCarousel from '../Components/MainCarousel/MainCarousel';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ClientCarousel from '../Components/ClientCarousel/ClientCarousel';
import ConsultationForm from '../Components/ConsultationForm/ConsultationForm';
import Testimonials from '../Components/Testimonials/Testimonials';
import Faq from '../Components/FAQItem/Faq';
import Footer from '../Components/Footer/Footer';
import useIsMobile from '../Components/useIsMobile';
import SolutionsSlide from '../Components/SolutionCard/SolutionSlide';
import ScrollToTopButton from '../Components/ScrollToTopButton/ScrollToTopButton';

function Home() {
  const isMobile = useIsMobile(); // Use the custom hook here
  return (
    
    <div className="App">
      {/* <Header /> */}
      <MainCarousel />
      {/* <HeroSection /> */}
      <ClientCarousel />
      <ConsultationForm />
      {/* <MasterpieceSlider /> */}
      {/* <TabMain /> */}
      {/* <SolutionsSection /> */}
     {isMobile ? ("") : (<SolutionsSlide />)}
      <Testimonials />
      <ScrollToTopButton />
    </div>
  );
}

export default Home;
