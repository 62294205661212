import React from 'react';
import './VideoSection.css';
import videothumb1 from "../../Assets/about/videothumb.png";
import videothumb2 from "../../Assets/about/videothumb2.png";
import { BsArrowUpRightCircle } from 'react-icons/bs';

const VideoSection = () => {
  return (
    <section className="video-section">
      <h2 className="title">Seeking Creative Superstars to Shape the Future</h2>
      <p className="subtitle">Help Us Build Tomorrow, One Design at a Time</p>
      <button className="m-auto bg-yellow-400 text-black color-white font-semibold py-2 px-6 rounded-full hover:bg-yellow-500 transition duration-300 flex items-center">Apply now <BsArrowUpRightCircle className="color-white"/></button>

      <div className="videos-container">
        <div className="video-item">
          <img
            src={videothumb1}
            alt="Design Work 1"
            className="video-thumbnail"
          />
          <div className="play-button" />
        </div>

        <div className="video-item">
          <img
            src={videothumb2}
            alt="Design Work 2"
            className="video-thumbnail"
          />
          <div className="play-button" />
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
