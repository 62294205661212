import React from "react";
import "./PlaceToWork.css";
import PlaceToWork1 from "../../Assets/Career/PlaceToWork1.png";
import PlaceToWork2 from "../../Assets/Career/PlaceToWork2.png";
import PlaceToWork3 from "../../Assets/Career/PlaceToWork3.png";
import PlaceToWork4 from "../../Assets/Career/PlaceToWork4.png";

const PlaceToWork = () => {
  const features = [
    {
      title: "Company Values",
      description:
        "Trust, learning, honesty, and co-operation are the pillars that sit at the core of what we do.",
      image: PlaceToWork1, // Replace with the actual image path
    },
    {
      title: "Friendly atmosphere",
      description:
        "We hold a positive attitude in order to foster respect and decency for our entire team.",
      image: PlaceToWork2, // Replace with the actual image path
    },
    {
      title: "Work-life balance",
      description:
        "For us, a healthy personal life is just as important as the time you spend in the office.",
      image: PlaceToWork3, // Replace with the actual image path
    },
    {
      title: "Everyday grow",
      description:
        "We provide the necessary support to ensure your skills never stop growing.",
      image: PlaceToWork4, // Replace with the actual image path
    },
  ];

  return (
    <div className="place-to-work-container">
      <h2 className="place-to-work-title">
        What makes digiworq a great <br />place to work?
      </h2>
      <div className="place-to-work-grid">
        {features.map((feature, index) => (
          <div key={index} className="place-to-work-card">
            <img
              src={feature.image}
              alt={feature.title}
              className="place-to-work-image"
            />
            <h3 className="place-to-work-card-title">{feature.title}</h3>
            <p className="place-to-work-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlaceToWork;
