import React from "react";
import StatsSection from "./Stats/Stats";
import TalktoUs from "./TalkToUs/TalkToUs";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-container">
        <div className="hero-content">
          <div className="hero-header">
            <h1 className="hero-title">
              In Between Supporting{" "}
              <span className="home-highlight">1K+ Brands</span> At A Time!
            </h1>
            <p className="hero-subtitle">Let’s talk and establish your brand.</p>
          </div>
          <StatsSection />
        </div>
      </div>
      <TalktoUs />
    </section>
  );
};

export default HeroSection;
