import logo from './logo.svg';
import './App.css';
import './styles/global.css';
import Navbar from './Navbar';
import Navmenu from './Components/Navmenu/Navmenu';
import MainCarousel from './Components/MainCarousel/MainCarousel';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './Components/Header/Header';
import HeroSection from './Components/HeroSection';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ClientCarousel from './Components/ClientCarousel/ClientCarousel';
import ConsultationForm from './Components/ConsultationForm/ConsultationForm';
import MasterpieceSlider from './Components/MasterpieceSlider/MasterpieceSlider';
import TabMain from './Components/Tabs/TabMain';
import Testimonials from './Components/Testimonials/Testimonials';
import Faq from './Components/FAQItem/Faq';
import Footer from './Components/Footer/Footer';
import TalkToUs from './Components/TalkToUs/TalkToUs';
import useIsMobile from './Components/useIsMobile';
import MobileMenu from './Components/MobileMenu/MobileMenu';

import AboutUs from './pages/AboutUs';
import Home from './pages/home';
import OurWorks from './pages/OurWorks';
import Services from './pages/Services';
import Career from './pages/Career';
import ContactUsPage from './pages/Contact';
import RouteHandler from './RouteHandler';
import ServicesList from './pages/ServiceList';
import Chatbot from './Components/Chatbot';
import Blogs from './pages/Blog';

function App() {
  const isMobile = useIsMobile(); // Use the custom hook here

  return (
    
    <div className="App">
      {/* <Header /> */}
      <Router>
      <Navbar />
      {isMobile ? (<MobileMenu />) : (<Navmenu />)}
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/OurWorks" element={<OurWorks />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/Career' element={<Career />} />
        <Route path='/Contact' element={<ContactUsPage />} />
        <Route path='/Blogs' element={<Blogs />} />
        <Route path="/" element={<ServicesList />} />
        <Route
          path="/services/:id"
          element={<RouteHandler />}
        />
        {/* <Route path="Services/Creative/graphic-design" element={<GraphicDesign />} /> */}
        {/* <Route path="/ui-ux-design" element={<UIUXDesign />} />
        <Route path="/mobile-app-design" element={<MobileAppDesign />} />
        <Route path="/ppt-design" element={<PPTDesign />} /> */}
      </Routes>
    </Router>
      <Faq />
      <Footer />
      <Chatbot />
    </div>
  );
}

export default App;
