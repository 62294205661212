import React, { useState } from "react";
import "./JobPositions.css";
import { FaArrowRight } from "react-icons/fa6";

const positions = [
  {
    title: "Full-Stack Developers",
    tags: ["Tartu", "Full-time", "Engineering"],
    description:
      "Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team.",
  },
  {
    title: "Application developer (React Native)",
    tags: ["Tartu", "Full-time", "Engineering"],
    description:
      "Due to growing workload, we are looking for experienced and talented Full-Stack Developers to join our fast-paced Engineering team.",
  },
  {
    title: "Senior Product Designer",
    tags: ["Hybrid", "Tallinn", "Full-time", "Design"],
    description:
      "Since 2019 we've worked on 30+ major projects from 8 different industries.",
  },
];

// Dynamically calculate categories
const categories = (() => {
  const allTags = positions.flatMap((position) => position.tags);
  const uniqueTags = Array.from(new Set(allTags));

  // Calculate count for each unique tag
  const categoryCounts = uniqueTags.reduce((acc, tag) => {
    acc[tag] = allTags.filter((t) => t === tag).length;
    return acc;
  }, {});

  return [
    { name: "All Positions", count: positions.length },
    ...Object.entries(categoryCounts).map(([name, count]) => ({
      name,
      count,
    })),
  ];
})();

const JobPositions = () => {
  const [selectedCategory, setSelectedCategory] = useState("All Positions");

  // Filter positions based on the selected category
  const filteredPositions =
    selectedCategory === "All Positions"
      ? positions
      : positions.filter((position) =>
          position.tags.includes(selectedCategory)
        );

  return (
    <div className="job-positions-container">
      {/* Sidebar */}
      <div className="job-positions-sidebar">
        <ul>
          {categories.map((category) => (
            <li
              key={category.name}
              className={`job-positions-category ${
                selectedCategory === category.name ? "active" : ""
              }`}
              onClick={() => setSelectedCategory(category.name)}
            >
              {category.name} ({category.count})
            </li>
          ))}
        </ul>
        <p>
          We are always seeking talented people. In case you cannot find your
          desired position here, please send us your LinkedIn profile and give
          us your contact information. We will be in touch.
        </p>
        <button className="job-positions-linkedin-button">
          Share your LinkedIn profile
        </button>
      </div>

      {/* Position List */}
      <div className="job-positions-list">
        <h2>
          {filteredPositions.length > 0
            ? `We have ${filteredPositions.length} open positions now!`
            : "No positions available for the selected category."}
        </h2>
        {filteredPositions.map((position, index) => (
          <div key={index} className="job-positions-card">
            <h3>{position.title}</h3>
            <div className="job-positions-tags">
              {position.tags.map((tag, idx) => (
                <span key={idx} className="job-positions-tag">
                  {tag}
                </span>
              ))}
            </div>
            <p>{position.description}</p>
            <button className="job-positions-cta-button bg-yellow-400 text-black color-white font-semibold py-2 px-6 rounded-full hover:bg-yellow-500 transition duration-300 flex items-center gap-2">
              See positions <FaArrowRight />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobPositions;
