import React from "react";
import "./BrandingPackageCarousel.css";
import { TiTick } from "react-icons/ti";
import { BsArrowUpRightCircle } from "react-icons/bs";

const BrandingPackageCarousel = ({ packages }) => {
    
  return (
    <div className="brandingpackagecarousel">
      <div className="brandingpackagecarousel-header">
        <h2>
          Our <br /><span>Branding Packages</span>
        </h2>
      </div>
      <div className="brandingpackagecarousel-wrapper">
        {packages.map((pack, index) => (
          <div
            key={index}
            className={`brandingpackagecarousel-card ${
              pack.isHighlighted ? "highlighted" : ""
            }`}
          >
            <h3 className="brandingpackagecarousel-title">{pack.title}</h3>
            <p className="brandingpackagecarousel-description">
              {pack.description}✔
            </p>
            <ul className="brandingpackagecarousel-features">
              {pack.features.map((feature, i) => (
                <li key={i} className="brandingpackagecarousel-feature">
                  <span className="checkmark"><TiTick /></span> {feature}
                </li>
              ))}
            </ul>
            <div className="brandingpackagecarousel-timeline">
              {pack.timeline} <span>Weeks to complete</span>
            </div>
            <button className="brandingpackagecarousel-btn">
              Request now <BsArrowUpRightCircle className="color-white"/>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandingPackageCarousel;
