import React, { useState } from "react";
import "./TalkToUs.css";
import { BsArrowUpRightCircle } from "react-icons/bs";

const TalkToUs = () => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("consultation"); // State to track the active tab
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    requirements: 'Brand Consultant',
    message: ''
  });

  const toggleFormVisibility = () => {
    setFormVisible(!isFormVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const response = await fetch('/send_email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData),
    });

    if (response.ok) {
      alert('Email sent successfully!');
    } else {
      alert('Failed to send email.');
    }

    // Reset the form
    setFormData({
      email: '',
      phone: '',
      requirements: 'Brand Consultant',
      message: ''
    });
  };

  // Function to switch the active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="talk-to-us-container">
      <button
        className={`fixed-toggle-button ${isFormVisible ? "" : "rotated"}`}
        onClick={toggleFormVisibility}
      >
        Talk to Our Experts
      </button>

      {isFormVisible && (
        <div className="form-container">
          <div className="form-content">
            {/* Tabs */}
            <div className="form-tabs">
              <button
                className={`tab ${activeTab === "consultation" ? "active" : ""}`}
                onClick={() => handleTabClick("consultation")}
              >
                Free Consultation
              </button>
              <button
                className={`tab ${activeTab === "contact" ? "active" : ""}`}
                onClick={() => handleTabClick("contact")}
              >
                Contact Us
              </button>
            </div>

            {/* Conditionally Render Content Based on Active Tab */}
            {activeTab === "consultation" && (
              <form className="form" onSubmit={sendEmail}>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="+91 96114 89001"
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Requirements</label>
                  <select
                    name="requirements"
                    value={formData.requirements}
                    onChange={handleInputChange}
                  >
                    <option value="Brand Consultant">Brand Consultant</option>
                    <option value="Web Development">Web Development</option>
                    <option value="SEO Services">SEO Services</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-yellow-400 text-black color-white font-semibold py-2 px-6 rounded-full hover:bg-yellow-500 transition duration-300 flex items-center"
                >
                  Submit <BsArrowUpRightCircle className="color-white" />
                </button>
              </form>
            )}

            {activeTab === "contact" && (
              <form className="form">
                <div className="form-group">
                  <label>Your Message</label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="Enter your message"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-yellow-400 text-black color-white font-semibold py-2 px-6 rounded-full hover:bg-yellow-500 transition duration-300 flex items-center"
                >
                  Send Message <BsArrowUpRightCircle className="color-white" />
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TalkToUs;
