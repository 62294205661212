import React, { useEffect, useState } from "react";
import { BsArrowUpRightCircle } from "react-icons/bs";
import "./Stats.css"
import ProgressBar from "../ProgressBar/ProgressBar";

const StatsSection = () => {
  const [counters, setCounters] = useState({
    strategies: 0,
    experience: 0,
    clients: 0,
    projects: 0,
  });

  useEffect(() => {
    const duration = 2000; // Animation duration in milliseconds
    const increment = 50; // Frequency of updates

    const startCount = (target, key) => {
      let count = 0;
      const step = target / (duration / increment);

      const timer = setInterval(() => {
        count += step;
        if (count >= target) {
          clearInterval(timer);
          count = target;
        }
        setCounters((prev) => ({ ...prev, [key]: Math.round(count) }));
      }, increment);
    };

    startCount(1, "strategies");
    startCount(10, "experience");
    startCount(25000, "clients");
    startCount(200, "projects");
  }, []);

  return (
    <div>
    <div className="stats-grid">
  <div>
    <h2 className="stat-number">1000+</h2>
    <ProgressBar progress={20} />
    <p className="stat-label">Creative <br />Strategies</p>
  </div>
  <div>
    <h2 className="stat-number">10+</h2>
    <ProgressBar progress={50} />
    <p className="stat-label">Years of <br />Experience</p>
  </div>
  <div>
    <h2 className="stat-number">25K</h2>
    <ProgressBar progress={75} />
    <p className="stat-label">Happy <br />Clients</p>
  </div>
  <div>
    <h2 className="stat-number">200+</h2>
    <ProgressBar progress={40} />
    <p className="stat-label">Projects <br />Cleared</p>
    {/* <ProgressBar progress={200} /> */}
  </div>
</div>
<button className="m-auto bg-yellow-400 text-black color-white font-semibold py-2 px-6 rounded-full hover:bg-yellow-500 transition duration-300 flex items-center">
        DigiworQ Now <BsArrowUpRightCircle className="color-white"/>
      </button>
      </div>
  );
};

export default StatsSection;
