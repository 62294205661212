import React from "react";
import "./InnerSectionBanner.css"; // CSS for styling
import { BsArrowUpRightCircle } from "react-icons/bs";

const InnerSectionBanner = ({ bannerData }) => {
  return (
    <div className="innersectionbanner">
      <div className="innersectionbanner-content">
        <h2 className="innersectionbanner-heading">
          {bannerData.title} <span className="highlight">{bannerData.highlight}</span>
        </h2>
        <button className="innersectionbanner-button">Let's Discuss <BsArrowUpRightCircle className="color-white"/>
        </button>
      </div>
    </div>
  );
};

export default InnerSectionBanner;
