import React, { useState, useEffect } from "react";
import "./SubServicesCarousel.css";
import { FaAngleLeft } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";

const SubServicesCarousel = ({ services, leftContent }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(3); // Default to 3 items (desktop)
  // Constants for navigation
//   const itemsToShow = 3; // Number of items visible at a time
   // Update `itemsToShow` based on screen width
   useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 768) {
        setItemsToShow(1); // Mobile
      } else if (screenWidth < 1024) {
        setItemsToShow(2); // Tablet
      } else {
        setItemsToShow(3); // Desktop
      }
    };

    handleResize(); // Run on mount
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);
  const totalItems = services.length;

  const handleNext = () => {
    if (currentIndex + itemsToShow < totalItems) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="subservicescarousel">
      <div className="subservicescarousel-left">
        <h2 className="subservicescarousel-title">
          {leftContent.title} <span>{leftContent.highlight}</span>
        </h2>
        <div className="subservicescarousel-description">
        <h3>{leftContent.subtitle}</h3>
        <p>
          {leftContent.description}
          
        </p>
        <button className="subservicescarousel-button">
                    I need this
                  </button>
        </div>
      </div>
      <div className="subservicescarousel-right">
        <div className="subservicescarousel-header">
          <div className="subservicescarousel-controls">
            <button
              className="prev"
              onClick={handlePrev}
              disabled={currentIndex === 0}
            >
             <FaAngleLeft />
            </button>
            <button
              className="next"
              onClick={handleNext}
              disabled={currentIndex + itemsToShow >= totalItems}
            >
             <FaAngleRight />
            </button>
          </div>
        </div>
        <div className="subservicescarousel-wrapper">
          <div
            className="subservicescarousel-inner"
            style={{
              transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)`,
              width: `${(totalItems / itemsToShow) * 100}%`,
            }}
          >
            {services.map((service, index) => (
              <div
                className="subservicescarousel-item"
                key={index}
                style={{
                  flex: `0 0 ${100 / itemsToShow}%`,
                }}
              >
                <div
                  className={`subservicescarousel-card ${
                    service.isHighlighted ? "highlighted" : ""
                  }`}
                >
                  
                  {service.icon && (
                    <img
                      src={service.icon}
                      alt={service.title}
                      className="subservicescarousel-icon"
                    />
                  )}
                  <h3>{service.title}<span className="highlighted">{service.highlighted}</span></h3>
                  {/* <p>{service.description}</p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubServicesCarousel;
