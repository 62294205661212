// src/components/FAQItem.js
import React, { useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";


const FAQItem = ({ question, answer, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={`faq-item ${isOpen ? "open" : ""}`}>
      <div className="faq-header" onClick={toggleOpen}>
        <div className="faq-number">{String(index).padStart(2, "0")}</div>
        <h3 className="faq-question">{question}</h3>
        <button className="faq-toggle">{isOpen ? <IoIosCloseCircleOutline />
 : <CiCirclePlus />
        }</button>
      </div>
      {isOpen && <p className="faq-answer">{answer}</p>}
    </div>
  );
};

export default FAQItem;
