// servicesData.js

const servicesData = [
  {
    id: "branding-solutions",
    title: "Branding Solutions",
    description: "We provide cutting-edge web development services.",
    keywords: "web development, programming, coding",
    category: "Services",
    subcategory: "Branding Solutions",
    bantitle: "Building Brands that Stand ",
    highlight: "For Generations",
    bandescription: "Raised by our startup clients",
    buttons: {
      primary: "Request for quote",
      secondary: "Tell me more",
    },
    videoban: "",
    ceoimage: "/images/ceo.png", // Replace with the CEO's image URL
    ceoname: "RK",
    ceotitle: "CEO at Digiworq",
    ceoquote:
      "In a world where customers seek authenticity and connection with their audience, we help them shape their company's identity. By actively engaging your audience through thoughtful branding and identity-building strategies, we enrich your brand to future-proof it for assured success.",
    brandingSteps: [
      {
        stepstitle: "Brand Research",
        stepsduration: "2-3 Days",
        stepsdescription:
          "We get started with researching the competitors and the ideations to build a better brand interface for the brand’s journey.",
        stepsdeliverables: ["Research", "Moodboard"],
      },
      {
        stepstitle: "Brand Strategy",
        stepsduration: "2-3 Days",
        stepsdescription:
          "On completing the research, we formulate a strategy that outlines the brand vision, mission, core values and the voice.",
        stepsdeliverables: ["Sketches", "Concepts explanation"],
      },
      {
        stepstitle: "Brand Identity",
        stepsduration: "5-7 Days",
        stepsdescription:
          "Next, we move in planning the brand identity that involves creating a logo, colour schemes and other design components.",
        stepsdeliverables: ["Final logo presentation", "Brand assets"],
      },
      {
        stepstitle: "Brand Implementation",
        stepsduration: "5-7 Days",
        stepsdescription:
          "The final step involves developing an identity implementing the planned campaigns and strategies across all touch points.",
        stepsdeliverables: ["Final logo presentation", "Brand assets"],
      },
    ],
    bannerData: {
      title: "Having a Challenge? ",
      highlight: "We are Ready!",
      buttonText: "Let’s DISCUSS",
    },
    leftContent: {
      title: "Our",
      highlight: "Branding Services",
      subtitle: "Custom Logo Design",
      description:
        "Custom logo designs go beyond aesthetics, as they reveal the core values and personality of your brand. That's why we make our logos versatile and scalable, filling them with depth and meanings through storytelling.",
    },
    services: [
      { title: "Brand book & ", highlighted: "Style Guide", icon: "/images/brandbook.png" },
      { title: "Naming", highlighted: "", icon: "/images/brandbook.png" },
      { title: "Letterhead", highlighted: "", icon: "/images/brandbook.png" },
      { title: "Standee", highlighted: "", icon: "/images/brandbook.png" },
      { title: "Banner", highlighted: "", icon: "/images/brandbook.png" },
    ],
    Workgallerytitle: {
      main: "Our Treasured  ",
      highlight: "Masterpieces",
      sub: "Work",
    },

    filters: ["All", "Finance", "Healthcare", "Auto", "Retail", "Other"],

    projects: [
      { title: "Project 1", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 2", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 2", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 2", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 2", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 2", description: "UI/UX Direction", image: "/images/healthcare.png", category: "Healthcare" },
      { title: "Project 3", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Auto" },
      { title: "Project 4", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Retail" },
      { title: "Project 5", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 6", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Other" },
    ],
    packages: [
      {
        title: "Starter",
        description:
          "You’ll receive a presentation of the logo design, its concept, visual components, color palette, and application possibilities.",
        features: [
          "Research & Moodboard",
          "Ideas generation",
          "Logo presentation",
          "Primary color palette",
          "Basic logo guidelines",
        ],
        timeline: "1-1.5",
        isHighlighted: false,
      },
      {
        title: "Advanced",
        description:
          "You’ll receive a presentation of the logo design, its concept, visual components, color palette, and application possibilities.",
        features: [
          "All perks from Starter",
          "Ideas generation",
          "Logo presentation",
          "Primary color palette",
          "Basic logo guidelines",
        ],
        timeline: "1-1.5",
        isHighlighted: true,
      },
      {
        title: "Super Pro",
        description:
          "You’ll receive a presentation of the logo design, its concept, visual components, color palette, and application possibilities.",
        features: [
          "Research & Moodboard",
          "Ideas generation",
          "Logo presentation",
          "Primary color palette",
          "Basic logo guidelines",
        ],
        timeline: "1-1.5",
        isHighlighted: false,
      },

    ],
    illustrations: ["Illustration 1", "Illustration 2", "Illustration 3"],
    stats: [
      { value: "10+", label: "Years of Experience" },
      { value: "200+", label: "Branding Projects" },
    ],
    banner: {
      title: "Build Your Future with Web Development",
      image: "/images/brandingban.png",
    },
    branding: {
      heading: "Why Choose Us?",
      content: "We deliver high-quality web development solutions tailored to your needs.",
    },
    serviceDetails: {
      sections: [
        {
          heading: "Custom Solutions",
          content: "Our team creates bespoke websites to fit your business goals.",
          image: "/images/custom-solutions.jpg",
        },
        {
          heading: "Responsive Design",
          content: "Your site will look great on any device, from desktops to smartphones.",
          image: "/images/responsive-design.jpg",
        },
      ],
    },
  },

  
  {
    id: "packaging-designing",
    title: "Package Designing",
    description: "We provide cutting-edge web development services.",
    keywords: "web development, programming, coding",
    category: "Services",
    subcategory: "Package Designing",
    bantitle: "Packaging that Speaks ",
    highlight: "Volumes of Happy Experiences",
    bandescription: "Raised by our startup clients",
    buttons: {
      primary: "Request for quote",
      secondary: "Tell me more",
    },
    videoban: "",
    ceoimage: "/images/ceo.png", // Replace with the CEO's image URL
    ceoname: "RK",
    ceotitle: "CEO at Digiworq",
    ceoquote:
      "A packaging design is crucial in capturing the consumer's attention and enriching brand identity. Our Package design gives you a visually stunning appearance and a uniqueness that perfectly resonates with your kind of audience. It not only keeps your products safe but also inscribes an untold story.",
    brandingSteps: [
      {
        stepstitle: "Discovery & Research",
        stepsduration: "2-3 Days",
        stepsdescription:
          "Our team of expert designers is involved in a one-on-one meeting with the client to understand the particular requirements.",
        stepsdeliverables: ["Research", "Moodboard"],
      },
      {
        stepstitle: "Concept Developmen",
        stepsduration: "3-5 Days",
        stepsdescription:
          "Our designers ideate and sketch a concept based on the research findings, focusing on aesthetics and brand messaging.",
        stepsdeliverables: ["Sketches", "Concepts explanation"],
      },
      {
        stepstitle: "Design Execution",
        stepsduration: "5-7 Days",
        stepsdescription:
          "The internally finalised concept is brought to life with an eye-catching design with graphics and other agreed elements of design.",
        stepsdeliverables: ["Final logo presentation", "Brand assets"],
      },
      {
        stepstitle: "Feedback & Approval",
        stepsduration: "5-7 Days",
        stepsdescription:
          "We share it for initial feedback with the client for suggestions. We get in a meeting with the client to share the final copy before we process it to print from our side if needed.",
        stepsdeliverables: ["Final logo presentation", "Brand assets"],
      },
    ],
    bannerData: {
      title: "Having a Challenge? ",
      highlight: "We are Ready!",
      buttonText: "Let’s DISCUSS",
    },
    leftContent: {
      title: "Our",
      highlight: "Packaging Services",
      subtitle: "Custom Logo Design",
      description:
        "From planning to execution and printing, you can rely on us for end-to-end solutions.",
    },
    services: [
      { title: "Custom Packaging ", highlighted: "Design", icon: "/images/brandbook.png" },
      { title: "Brand Identity ", highlighted: "Integration", icon: "/images/brandbook.png" },
      { title: "Sustainable Packaging ", highlighted: "Solutions", icon: "/images/brandbook.png" },
      { title: "Label ", highlighted: "Design", icon: "/images/brandbook.png" },
      { title: "Structural Design ", highlighted: "for Packaging", icon: "/images/brandbook.png" },
      { title: "Market Research ", highlighted: "and Analysis", icon: "/images/brandbook.png" },
      { title: "Graphic Design ", highlighted: "for Packaging", icon: "/images/brandbook.png" },
      { title: "Packaging Compliance ", highlighted: "Consultation", icon: "/images/brandbook.png" },
      { title: "Post-Launch Evaluation ", highlighted: "and Optimization", icon: "/images/brandbook.png" },
    ],
    Workgallerytitle: {
      main: "Our ",
      highlight: "Treasured",
      sub: "Masterpieces",
    },

    filters: ["All", "Finance", "Healthcare", "Auto", "Retail", "Other"],

    projects: [
      { title: "Project 1", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 2", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 2", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 2", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 2", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 2", description: "UI/UX Direction", image: "/images/healthcare.png", category: "Healthcare" },
      { title: "Project 3", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Auto" },
      { title: "Project 4", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Retail" },
      { title: "Project 5", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Finance" },
      { title: "Project 6", description: "UI/UX Direction", image: "/images/brandinggallery.png", category: "Other" },
    ],
    packages: [
      {
        title: "Starter",
        description:
          "You’ll receive a presentation of the logo design, its concept, visual components, color palette, and application possibilities.",
        features: [
          "Research & Moodboard",
          "Ideas generation",
          "Logo presentation",
          "Primary color palette",
          "Basic logo guidelines",
        ],
        timeline: "1-1.5",
        isHighlighted: false,
      },
      {
        title: "Advanced",
        description:
          "You’ll receive a presentation of the logo design, its concept, visual components, color palette, and application possibilities.",
        features: [
          "All perks from Starter",
          "Ideas generation",
          "Logo presentation",
          "Primary color palette",
          "Basic logo guidelines",
        ],
        timeline: "1-1.5",
        isHighlighted: true,
      },
      {
        title: "Super Pro",
        description:
          "You’ll receive a presentation of the logo design, its concept, visual components, color palette, and application possibilities.",
        features: [
          "Research & Moodboard",
          "Ideas generation",
          "Logo presentation",
          "Primary color palette",
          "Basic logo guidelines",
        ],
        timeline: "1-1.5",
        isHighlighted: false,
      },

    ],
    illustrations: ["Illustration 1", "Illustration 2", "Illustration 3"],
    stats: [
      { value: "10+", label: "Years of Experience" },
      { value: "200+", label: "Branding Projects" },
    ],
    banner: {
      title: "Build Your Future with Web Development",
      image: "/images/brandingban.png",
    },
    branding: {
      heading: "Why Choose Us?",
      content: "We deliver high-quality web development solutions tailored to your needs.",
    },
    serviceDetails: {
      sections: [
        {
          heading: "Custom Solutions",
          content: "Our team creates bespoke websites to fit your business goals.",
          image: "/images/custom-solutions.jpg",
        },
        {
          heading: "Responsive Design",
          content: "Your site will look great on any device, from desktops to smartphones.",
          image: "/images/responsive-design.jpg",
        },
      ],
    },
  },
  {
    id: "graphic-design",
    title: "Graphic Design",
    description: "High-quality graphic design services for your business.",
    keywords: "graphic design, branding, logos",
    banner: {
      title: "Bring Your Ideas to Life with Graphic Design",
      image: "/images/graphic-design-banner.jpg",
    },
    branding: {
      heading: "Creative Excellence",
      content: "We create visually stunning designs that make an impact.",
    },
    serviceDetails: {
      sections: [
        {
          heading: "Brand Identity",
          content: "We craft unique logos and branding materials.",
          image: "/images/brand-identity.jpg",
        },
        {
          heading: "Marketing Materials",
          content: "From brochures to social media graphics, we do it all.",
          image: "/images/marketing-materials.jpg",
        },
      ],
    },
  },
];

export default servicesData;
