import React, { useState } from "react";
import "./LetsTalk.css";
import { FaCheckCircle, FaPaperclip } from "react-icons/fa";
import letstalk from "../../Assets/Contact/letstalk.png";

const LetsTalk = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    budget: '',
    projectDetails: '',
    file: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  const sendFormData = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("name", formData.name);
    form.append("email", formData.email);
    form.append("mobile", formData.mobile);
    form.append("budget", formData.budget);
    form.append("projectDetails", formData.projectDetails);
    if (formData.file) {
      form.append("file", formData.file);
    }

    const response = await fetch("/mail.php", {
      method: "POST",
      body: form,
    });

    if (response.ok) {
      alert("Your message has been sent!");
      // Reset form after submission
      setFormData({
        name: '',
        email: '',
        mobile: '',
        budget: '',
        projectDetails: '',
        file: null,
      });
    } else {
      alert("There was an error sending your message.");
    }
  };

  return (
    <div className="letstalk-container">
      <div className="letstalk-content">
        {/* Left Section */}
        <div className="letstalk-info">
          <h1>Let’s Talk</h1>
          <ul>
            <li>
              <FaCheckCircle className="letstalk-icon" />
              We will respond to you within 24 hours.
            </li>
            <li>
              <FaCheckCircle className="letstalk-icon" />
              We’ll sign an NDA if requested.
            </li>
            <li>
              <FaCheckCircle className="letstalk-icon" />
              Access to dedicated product specialists.
            </li>
          </ul>
          <div className="letstalk-contact">
            <img
              src={letstalk}
              alt="Contact Person"
              className="letstalk-avatar"
            />
            <p>
              <strong>Direct Contact</strong>
              <br />
              <a href="mailto:rk@digiworq.com">rk@digiworq.com</a>
            </p>
          </div>
        </div>

        {/* Right Section */}
        <form className="letstalk-form" onSubmit={sendFormData}>
          <div className="letstalk-form-group">
            <input
              type="text"
              placeholder="Name*"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <input
              type="email"
              placeholder="Email*"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="letstalk-form-group">
            <input
              type="text"
              placeholder="Mobile*"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              placeholder="What is your budget?"
              name="budget"
              value={formData.budget}
              onChange={handleInputChange}
            />
          </div>
          <textarea
            placeholder="About Project*"
            rows="4"
            name="projectDetails"
            value={formData.projectDetails}
            onChange={handleInputChange}
            required
            className="letstalk-textarea"
          ></textarea>
          <div className="letstalk-form-footer">
            <label className="letstalk-attachment">
              <FaPaperclip />
              <span>.doc, .pdf 15 mb max</span>
              <input type="file" onChange={handleFileChange} />
            </label>
            <button type="submit" className="letstalk-submit-button">
              Send a message
            </button>
          </div>
          <p className="letstalk-policy">
            By submitting the form I agree with the{" "}
            <a href="/privacy-policy" className="letstalk-link">
              Privacy Policy
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LetsTalk;
