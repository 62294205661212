import React, { useState } from "react";
import "./Navmenu.css";
import logo1 from "../../Assets/icons/logo.png";
import list from "../../Assets/icons/🦆 icon _List_.png";
import "./Navmenu.css"
import { FaBarsStaggered } from "react-icons/fa6";
import { FaAngleDown,FaPaintBrush,FaBox } from "react-icons/fa";
import DropdownMenu from "./DropdownMenu";
import banimage1 from "../../Assets/banners/ban-branding.png"
import MobileMenu from "../MobileMenu/MobileMenu";

const Navmenu = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track if dropdown is open
  const menuItems = [
    { label: "Creative", href: "/products", hasIcon: true ,dropdown: {
      title: "Creative Design",
      subtitle: "fhdsfjdhgjdfkgkd",
      items: [
        { label: "Branding Solutions", href: "/Services/branding-solutions", description: "Learn about your users",hasIcon: true,icon: <FaPaintBrush />, image: banimage1,
          imageAlt: "Branding Solutions Example",},
        { label: "Packaging Designing", href: "/Services/packaging-designing", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Graphic Design", href: "/Services/graphic-design", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "UI/UX Design", href: "/Services/ui-ux-design", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Mobile App Design", href: "/Services/mobile-app-design", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "PPT Design", href: "/Services/ppt-design", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Brand Consultation", href: "/Services/brand-consultation", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Infographic Design", href: "/Services/infographic-design", description: "Learn about your users",hasIcon: true,icon: <FaPaintBrush />, image: banimage1,
          imageAlt: "Branding Solutions Example",},
        { label: "Packaging Designing", href: "/packaging-designing", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Infographic Design", href: "/infographic-design", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Illustrations Recreation", href: "/illustrations-recreation", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Social Media ADS", href: "/social-media-ads", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Illustrations Recreation", href: "/illustrations-recreation", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },
        { label: "Social Media ADS", href: "/social-media-ads", description: "Learn about your users",hasIcon: true,icon: <FaBox />, },

      ],
      
    },},
    { label: "Technology", href: "/about", hasIcon: true, 
    //   dropdown: {
    //   title: "Innovative Tech Solutions",
    //   subtitle: "Shaping the future",
    //   items: [
    //     { label: "Web Development", href: "/web-development", description: "Build modern web apps" },
    //     { label: "Mobile Apps", href: "/mobile-apps", description: "Create intuitive apps" },
    //     { label: "Cloud Services", href: "/cloud-services", description: "Explore cloud solutions" },
    //   ],
    //   image: "https://via.placeholder.com/300",
    //   imageAlt: "Technology Innovations",
    // },
   },
    { label: "Video Production", href: "/contact", hasIcon: true,
      // dropdown: {
      //   title: "Engaging Video Content",
      //   subtitle: "Create impactful stories",
      //   items: [
      //     { label: "Corporate Videos", href: "/corporate-videos", description: "Professional videos for your brand" },
      //     { label: "Animation", href: "/animation", description: "2D and 3D animations" },
      //     { label: "Post-Production", href: "/post-production", description: "Enhance your videos" },
      //   ],
      //   image: "https://via.placeholder.com/300",
      //   imageAlt: "Video Production",
      // },
     },
    { label: "Digital Marketing", href: "/Services/branding-solutions", hasIcon: true },
    { label: "2D Animation", href: "/", hasIcon: true },
    { label: "3D Animation", href: "/", hasIcon: true },
    { label: "Printing Solution", href: "/", hasIcon: true },
    { label: "Photography", href: "/", hasIcon: true },
    { label: "Videography", href: "/", hasIcon: true },
  ];
  const handleMouseEnter = (index) => {
    setActiveMenu(index);
    setIsDropdownOpen(true); // Keep dropdown open
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
    setIsDropdownOpen(false); // Close dropdown on mouse leave
  };
  return (

<div className="Navmenu">
  <div className="header-left">
  <img loading="lazy" src={logo1} alt="logo1" className='img-fluid'></img>
  </div>
  <div className="header-center mobile-nav">
      <ul className="head-links">
      {menuItems.map((item, index) => (
          <li
            key={index}
            className="menu-item"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <a href={item.href} className="menu-link">
              {item.label} {item.hasIcon && <FaAngleDown style={{color:"#FFb000"}} />}
            </a>
            {isDropdownOpen && activeMenu === index && item.dropdown && (
              <DropdownMenu
              title={item.dropdown.title}
              subtitle={item.dropdown.subtitle}
              items={item.dropdown.items}
              image={item.dropdown.image}
              imageAlt={item.dropdown.imageAlt}
            />
)}

          </li>
        ))}
      </ul>
    </div>
  <div className="header-right">
    <a href="/Contact" className="cart-icon menu-right">
      {/* <i className="fas fa-shopping-cart"></i> */}
      <button className='menubutton'><span className="">Contact Us</span></button>
    </a>
    {/* <img loading="lazy" src={list} alt="list" className='img-fluid menu-list'></img> */}
    <FaBarsStaggered className='menu-list'/>
  </div>

</div>
);
};

export default Navmenu;