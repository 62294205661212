// Import necessary libraries
import React from 'react';
import './Location.css'; // Include a separate CSS file for styling
import map from "../../Assets/Contact/map.png"
import letstalk from "../../Assets/Contact/letstalk.png"
import { IoLogoLinkedin } from "react-icons/io";

// Reusable LocationCard Component
const LocationCard = ({ region, address, comingSoon }) => {
  return (
    <div className="location__card">
      <div className="location__map">
        {/* Add a map illustration or placeholder */}
        <div className={`location__map-${region.toLowerCase().replace(' ', '-')}`}><img
              src={map}
              alt="map"
              className=""
            /></div>
      </div>
      <div className="location__details">
        <h3>{region}</h3>
        {comingSoon ? (
          <p>Coming Soon</p>
        ) : (
          <address>{address}</address>
        )}
      </div>
    </div>
  );
};

// Main Location Component
const LocationPage = () => {
  return (
    <div className="location">
      <div className="location__grid">
        {/* South Asian Location */}
        <LocationCard
          region="South Asian"
          address="4/20, 1st B Main Road 1st N Block, Near Ganapathi Temple Extension, Rajajinagar, Bengaluru, Karnataka 560010"
        />

        {/* Australia Location */}
        <LocationCard region="Southern Hemisphere Australia" comingSoon />
      </div>

      {/* Footer Section */}
      <div className="location__footer">
        <div className='flex gap-5 items-center'>
            <div><img
          src={letstalk} // Placeholder for profile picture
          alt="RK"
          className="location__profile-picture"
        /></div>
        
        <div>
          <h3>RK</h3>
          <h4 className='flex  gap-5'>ceo @ Digiworq <IoLogoLinkedin /></h4>
          {/* <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="location__linkedin-link"
          >
            Connect on LinkedIn
          </a> */}
        </div>
        </div>
        <div><h3>"have more questions or just curious about future possibilities?</h3></div>
      </div>
    </div>
  );
};

export default LocationPage;
