import React, { useState, useEffect } from "react";
import { IoCloseCircle } from "react-icons/io5";

const Chatbot = () => {
  const [messages, setMessages] = useState([
    { text: "Hi there! How can I help you?", sender: "bot" },
  ]);
  const [input, setInput] = useState("");
  const [isOpen, setIsOpen] = useState(false); // Initially closed
  const [showChatbotButton, setShowChatbotButton] = useState(false); // Show button on scroll

  // Handle scroll to show the chatbot button
  const handleScroll = () => {
    if (window.scrollY > 200) {
      setShowChatbotButton(true);
    } else {
      setShowChatbotButton(false);
    }
  };

  // Listen to scroll events
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Simulate automatic bot message after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Don't hesitate to ask me anything!", sender: "bot" },
      ]);
    }, 5000); // 5 seconds delay

    return () => clearTimeout(timer); // Cleanup timeout
  }, []);

  const handleUserMessage = async () => {
    if (!input.trim()) return;

    const newMessages = [...messages, { text: input, sender: "user" }];
    setMessages(newMessages);

    // Send user message to the server and get bot response
    try {
      const response = await fetch('/chatbot.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: input }),
      });

      const data = await response.json();

      // Set bot's reply
      const botReply = data.reply || "I didn't understand that.";
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: botReply, sender: "bot" },
      ]);
    } catch (error) {
      console.error("Error sending message to the server:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Sorry, there was an error. Please try again.", sender: "bot" },
      ]);
    }

    setInput("");
  };

  return (
    <>
      {/* Chatbot Toggle Button */}
      {showChatbotButton && !isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          style={{
            position: "fixed",
            bottom: "80px",
            right: "20px",
            backgroundColor: "#ffbb00",
            color: "#fff",
            border: "none",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            fontSize: "20px",
            cursor: "pointer",
            animation: "blink 1.5s infinite", // Blinking effect when button is visible
          }}
        >
          💬
        </button>
      )}

      {/* Chatbot Container */}
      {isOpen && (
        <div
          style={{
            position: "fixed",
            bottom: "60px",
            right: "40px",
            maxWidth: "300px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            backgroundColor: "#fff",
            textAlign: "center",
          }}
        >
          {/* Close Button */}
          <button
            onClick={() => setIsOpen(false)}
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              backgroundColor: "transparent",
              border: "none",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            <IoCloseCircle />
          </button>

          {/* Chat Messages */}
          <div
            style={{
              height: "150px",
              overflowY: "scroll",
              border: "1px solid #ffc107",
              padding: "10px",
              borderRadius: "10px 10px 0 0",
            }}
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                style={{
                  textAlign: msg.sender === "bot" ? "left" : "right",
                  margin: "5px 0",
                }}
              >
                <strong>{msg.sender === "bot" ? "Bot" : "You"}:</strong>{" "}
                {msg.text}
              </div>
            ))}
          </div>

          {/* Input and Send Button */}
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type a message..."
            style={{
              width: "70%",
              padding: "10px",
              marginRight: "10px",
              borderRadius: "5px",
              border: "1px solid #ddd",
            }}
          />
          <button
            onClick={handleUserMessage}
            style={{
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#ffbb00",
              color: "#000",
              border: "none",
            }}
          >
            Send
          </button>
        </div>
      )}

      {/* Adding keyframes for blinking */}
      <style>{`
        @keyframes blink {
          50% {
            opacity: 0.5;
          }
        }
      `}</style>
    </>
  );
};

export default Chatbot;
