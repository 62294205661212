// src/App.js
import React from "react";
import "./FaqItem.css"
import FAQItem from "./FAQItem";

const faqData = [
  {
    question: "Frequently Asked Questions",
    answer:
      "By accessing this website, we assume you accept these terms and conditions. Do not continue to use digiworq if you do not agree to take all of the terms and conditions stated on this page.",
  },
  {
    question: "What is the purpose of this service?",
    answer:
      "Our service provides reliable and efficient solutions to meet your needs. Learn more about our features.",
  },
  {
    question: "How can I contact support?",
    answer: "You can reach out to our support team via email or live chat 24/7.",
  },
  {
    question: "What are the payment options?",
    answer: "We accept credit cards, PayPal, and other payment methods.",
  },
];

const Faq = () => {
  return (
    <div className="faq-container">
      <header className="faq-title">
        <h1>Frequently Asked Questions</h1>
        <p>At eu lobortis pretium tincidunt amet lacus ut aenean aliquet</p>
      </header>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            index={index + 1}
          />
        ))}
      </div>
    </div>
  );
};

export default Faq;
