import React from "react";

const BlogPage = ({ posts }) => {
  return (
    <div className="blog-page">
      {posts.map((post) => (
        <div key={post.id} className="blog-post">
          <h2 className="blog-title">{post.title}</h2>
          {post.image && (
            <img src={post.image} alt={post.title} className="blog-image" />
          )}
          <div
            className="blog-content"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>
      ))}
    </div>
  );
};

export default BlogPage;
