// Main entry file: App.js
import React from 'react';
import ServicesBanner from '../Components/Servicesbanner/ServicesBanner';
import ServiceData from '../Components/Servicesbanner/ServiceData';
import Process from '../Components/Servicesbanner/Process';
import { BsArrowUpRightCircle } from 'react-icons/bs';
import ClientCarousel from '../Components/ClientCarousel/ClientCarousel';
import ConsultationForm from '../Components/ConsultationForm/ConsultationForm';
// import ServiceAccordion from '../Components/ServiceAccordion/ServiceAccordion';
import "../styles/Services.css"
import ServiceAccordion from '../Components/ServiceAccordion/ServiceAccordion';
import StayFocus from '../Components/StayFocus/StayFocus';
import OurProcess from '../Components/OurProcess/OurProcess';
import DisruptingIndustries from '../Components/DisruptingIndustries/DisruptingIndustries';
import Testimonials from '../Components/Testimonials/Testimonials';
import BookACall from '../Components/BookACall/BookACall';
import ScrollToTopButton from '../Components/ScrollToTopButton/ScrollToTopButton';

const Services = () => {
  return (
    <div className='services'>
     <h1>Our Full Menu of, <br /><span className="services-highlight">360° Digital Possibilities…</span></h1>
     <p>404: Boring Marketing & Technology Usage Not Found</p>
      <ServicesBanner />
      <div className='inline-flex gap-2'>
      <button className="m-auto bg-black text-white color-yellow font-semibold py-2 px-6 rounded-full hover:bg-yellow-500 transition duration-300 flex items-center gap-2">
              Request for a quote <BsArrowUpRightCircle className="color-white"/>
            </button>
            <button className="m-auto bg-yellow-400 text-black color-white font-semibold py-2 px-6 rounded-full hover:bg-black hover:text-white transition duration-300 flex items-center gap-2">
              Contact Us <BsArrowUpRightCircle className="color-white"/>
            </button>
            </div>
            <ClientCarousel />
            <div>
      <h2 className="services-highlight">Services</h2>
      <p >
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that it has a more-or-less normal distribution of
        letters, as opposed to using 'Content here, content here', making it
        look like readable English. It is a long established fact that a reader
        will be distracted by the readable content of a page when looking at
        its layout. The point of using Lorem Ipsum is that it has a more-or-less
        normal distribution of letters, as opposed to using 'Content here,
        content here', making it look like readable English.
      </p>

            </div>
            <ConsultationForm />
            <ServiceAccordion />
            {/* <div className='flex'><div>fdgdfg</div>
            <div><ServiceAccordion /></div>
            </div> */}
            <StayFocus />
      {/* <ServiceData /> */}
      <OurProcess />
      <DisruptingIndustries />
      <Testimonials />
      <BookACall />
      {/* <Process /> */}
      <ScrollToTopButton />
    </div>
  );
};

export default Services;