import React from 'react';
import './CraftingSection.css';
import image1 from "../../Assets/about/crafting1.png";
import image2 from "../../Assets/about/crafting2.png";
import image3 from "../../Assets/about/crafting3.png";
import image4 from "../../Assets/about/crafting4.png";
import ImageCard from '../ImageCard/ImageCard';
// import ImageCard from '../ImageCard/ImageCard';

// Example image imports or URLs
// const images = [
//   { src: craftingImage1, alt: 'Image 1' },
//   { src: craftingImage2, alt: 'Image 2' },
//   { src: craftingImage3, alt: 'Image 3' },
//   { src: craftingImage4, alt: 'Image 4' },
// ];

const CraftingSection = () => {
  return (
    <section className="crafting-section">
      <div className="image-grid">
        {/* First row */}
        <div className="small">
          {/* <img src={image1} alt="Small Image 1" /> */}
          <ImageCard imageSrc={image1} altText={"Small Image 1"} />
        </div>
        <div className="large">
        <ImageCard imageSrc={image2} altText={"Large Image 2"} />
        </div>
        </div>
        {/* Second row */}
        <div className="image-grid1">

        <div className="large">
        <ImageCard imageSrc={image3} altText={"Large Image 3"} />
        </div>
        <div className="small">
        <ImageCard imageSrc={image4} altText={"Small Image 4"} />
        </div>
      </div>
    </section>
  );
};

export default CraftingSection;
