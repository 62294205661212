import React from "react";
import "./SubservicesBanner.css";
import { PiGreaterThan } from "react-icons/pi";
import { BsArrowUpRightCircle } from "react-icons/bs";

const SubservicesBanner = ({ servicesData }) => {
  return (
    <div className="subservicesbanner-container">
      {/* Breadcrumbs */}
      <nav className="subservicesbanner-breadcrumbs">
        <span>{servicesData.category}</span> <PiGreaterThan style={{color:"#ffbb00"}}/> <span>{servicesData.subcategory}</span>
      </nav>

      {/* Main Content */}
      <div className="subservicesbanner-content">
        <div className="subservicesbanner-text">
          <h1>
            {servicesData.bantitle} <span>{servicesData.highlight}</span>
          </h1>
          <p>{servicesData.bandescription}</p>
          <div className="subservicesbanner-buttons">
            <button className="btn-primary">{servicesData.buttons.primary}<BsArrowUpRightCircle className="color-white"/></button>
            <button className="btn-secondary">{servicesData.buttons.secondary}<BsArrowUpRightCircle className="color-white"/></button>
          </div>
        </div>
        
        {/* Illustration */}
        <div className="subservicesbanner-illustration">
          {/* {servicesData.illustrations.map((item, index) => (
            <div key={index} className="illustration-item">{item}</div>
          ))} */}
          <img src={servicesData.banner.image} alt={servicesData.banner.image} className="banner-image" />
        </div>
      </div>

      {/* Stats Section */}
      {/* <div className="subservicesbanner-stats">
        {servicesData.stats.map((stat, index) => (
          <div key={index} className="stat-item">
            <h2>{stat.value}</h2>
            <p>{stat.label}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default SubservicesBanner;
