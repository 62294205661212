import React, { useState } from 'react';

const ApplicationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    institution: '',
    message: '',
    resume: null,
    termsAccepted: false,
  });

  const [statusMessage, setStatusMessage] = useState('');

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      if (key === 'resume' && value) {
        formDataToSend.append(key, value, value.name);
      } else {
        formDataToSend.append(key, value);
      }
    }

    try {
      const response = await fetch('/path/to/your/php/process_form.php', {
        method: 'POST',
        body: formDataToSend,
      });

      const result = await response.text();
      setStatusMessage(result); // Display success or error message from PHP
    } catch (error) {
      setStatusMessage('Error submitting the form.');
    }
  };

  return (
    <div>
      <h2>Career Application</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Phone:</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Location:</label>
          <input
            type="text"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Institution:</label>
          <input
            type="text"
            name="institution"
            value={formData.institution}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Message:</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Upload Resume:</label>
          <input
            type="file"
            name="resume"
            onChange={handleChange}
          />
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              name="termsAccepted"
              checked={formData.termsAccepted}
              onChange={handleChange}
            />
            I accept the terms and conditions
          </label>
        </div>
        <button type="submit">Submit Application</button>
      </form>

      {statusMessage && <p>{statusMessage}</p>}
    </div>
  );
};

export default ApplicationForm;
