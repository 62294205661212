import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Updated import
import "swiper/css/pagination"; // Optional feature
import "swiper/css/navigation"; // Optional feature
import "./ServiceAccordion.css";
import { Link } from "react-router-dom";
// creative
import creative1 from "../../Assets/services/creative/creative1.png";
import creative2 from "../../Assets/services/creative/creative2.png";
import creative3 from "../../Assets/services/creative/creative3.png";
// Technology
import technology1 from "../../Assets/services/creative/creative3.png";
import technology2 from "../../Assets/services/creative/creative2.png";
import technology3 from "../../Assets/services/creative/creative1.png";
import technology4 from "../../Assets/services/creative/creative3.png";
import useIsMobile from '../useIsMobile';

const ServiceAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const isMobile = useIsMobile(); // Use the custom hook here

  const services = [
    {
      title: "Creative",
      description:
        "In a world where first impressions depend on our innovation and branding offerings designed to focus on your brand identity and make it unforgettable, we delve deep into your emblem's essence, curating compelling narratives that resonate together with your audience. Expect progressive layout answers that no longer best seize interest; however, they additionally foster emotional connections from brand creation to properly planned logo strategies. We ensure your logo sticks out in a crowded marketplace with our know-how. Your business will exude professionalism and authenticity, leaving an enduring effect that drives engagement and loyalty. Let us rework your vision right into a powerful brand tale that captivates.",
      categories: [
        "Graphic Design",
        "UI/UX Design",
        "Mobile App Design",
        "PPT Design",
        "Brand Consultant",
        "Content Creation",
        "Packaging Designing",
        "Illustration",
        "Execution Services",
        "Rebranding Solutions",
        "Social Media",
        "Advertisement Banner",
      ],
      images: [
        {
          src: creative1,
          title: "Project title",
          description: "UI, UX direction",
          alt: "An example of a UI/UX design project",
        },
        {
          src: creative2,
          title: "Project title",
          description: "UI, UX direction",
          alt: "A creative design for a mobile application",
        },
        {
          src: creative3,
          title: "Project title",
          description: "UI, UX direction",
          alt: "A branding design project showcasing logo and typography",
        },
      ],
    },
    {
      title: "Technology",
      description:
        "Step into destiny with our progressive era answers with our destiny-proof technology solutions that facilitate your commercial enterprise to excel. We offer a multitude of modern-day offerings designed to enhance performance and foster growth. count on seamless integration of contemporary advanced technologies that streamline your operations and facilitate collaboration across teams. Our professional insights guide you in making informed choices that leverage statistics for strategic advantage. Whether you need cloud solutions, cybersecurity measures, or AI-pushed equipment, we equip you with the sources to live ahead of the curve. Enjoy transformative technology that propels your commercial enterprise to state-of-the-art, sustainable success. ",
      categories: ["Graphic Design", "UI/UX Design", "Mobile App Design", "PPT Design"],
      images: [
        { src: technology1, title: "Project title", description: "UI, UX direction" },
        { src: technology2, title: "Project title", description: "UI, UX direction" },
        { src: technology3, title: "Project title", description: "UI, UX direction" },
        { src: technology4, title: "Project title", description: "UI, UX direction" },
      ],
    },
    {
      title: "Video Production",
      description:
        "Seize the essence of modern-day your emblem via our expert video production offerings, where storytelling meets visual artistry. We specialise in growing high-impact videos that interact and inspire audiences throughout structures. Assume meticulously crafted content material that showcases your services or products in a proper light using better engagement and conversion rates from idea improvement to submit-manufacturing. Our team guarantees each frame communicates your message correctly with our expertise. You could count on a tremendous comeback on funding as we increase your advertising strategy through compelling visual narratives that resonate with visitors. ",
      categories: ["Graphic Design", "UI/UX Design", "Mobile App Design", "PPT Design"],
      images: [
        { src: technology1, title: "Project title", description: "UI, UX direction" },
        { src: technology2, title: "Project title", description: "UI, UX direction" },
        { src: technology3, title: "Project title", description: "UI, UX direction" },
        { src: technology4, title: "Project title", description: "UI, UX direction" },
      ],
    },
    {
      title: "2D Animation",
      description:
        "Deliver your thoughts to existence with our charming second and 3D animation services that mix creativity with technical precision. We focus on developing visually stunning animations that correctly speak complex standards in an enticing manner. Anticipate specific animations tailored to suit your logo's character, whether or not for promotional motion pictures, explainer content, or instructional material. Our skilled animators utilise best-in-class technology to make certain the desired output captivates audiences and complements viewer retention with our animation offerings. You can assume an immersive revel that elevates your storytelling and leaves a lasting impression. ",
      categories: ["Graphic Design", "UI/UX Design", "Mobile App Design", "PPT Design"],
      images: [
        { src: technology1, title: "Project title", description: "UI, UX direction" },
        { src: technology2, title: "Project title", description: "UI, UX direction" },
        { src: technology3, title: "Project title", description: "UI, UX direction" },
        { src: technology4, title: "Project title", description: "UI, UX direction" },
      ],
    },
    {
      title: "3D Animation",
      description:
        "Deliver your thoughts to existence with our charming second and 3D animation services that mix creativity with technical precision. We focus on developing visually stunning animations that correctly speak complex standards in an enticing manner. Anticipate specific animations tailored to suit your logo's character, whether or not for promotional motion pictures, explainer content, or instructional material. Our skilled animators utilise best-in-class technology to make certain the desired output captivates audiences and complements viewer retention with our animation offerings. You can assume an immersive revel that elevates your storytelling and leaves a lasting impression. ",
      categories: ["Graphic Design", "UI/UX Design", "Mobile App Design", "PPT Design"],
      images: [
        { src: technology1, title: "Project title", description: "UI, UX direction" },
        { src: technology2, title: "Project title", description: "UI, UX direction" },
        { src: technology3, title: "Project title", description: "UI, UX direction" },
        { src: technology4, title: "Project title", description: "UI, UX direction" },
      ],
    },
    {
      title: "Printing Services",
      description:
        "Transform your imagination and vision into tangible reality with our top-rate printing offerings that cater to all your branding desires. We provide a wide range of ultra-modern printing answers, from commercial enterprise playing cards to big-layout prints, ensuring a desired output each time. Count on colourful colours and sharp information that reflect the professionalism of your emblem. Our team is dedicated to offering brief turnaround instances without compromising high quality, ensuring you meet closing dates. results easily with our printing services, you may anticipate materials that not only have a splendid appearance but also effectively talk your message, improving your emblem's visibility in any place. ",
      categories: ["Graphic Design", "UI/UX Design", "Mobile App Design", "PPT Design"],
      images: [
        { src: technology1, title: "Project title", description: "UI, UX direction" },
        { src: technology2, title: "Project title", description: "UI, UX direction" },
        { src: technology3, title: "Project title", description: "UI, UX direction" },
        { src: technology4, title: "Project title", description: "UI, UX direction" },
      ],
    },
    {
      title: "Photography Services",
      description:
        "Capture the essence of your brand through our professional picture offerings designed to inform compelling stories through pictures. We pay attention to handing over and placing visuals that resonate with your audience, whether or not for product showcases or corporate activities. Expect meticulous attention to detail and creativity that highlights the specific elements of modern-day what you offer. Our photographers excel at growing true imagery that builds agreement with and references to capacity customers with our picture services. Look forward to effective visuals that enhance your advertising efforts and create lasting impressions. ",
      categories: ["Graphic Design", "UI/UX Design", "Mobile App Design", "PPT Design"],
      images: [
        { src: technology1, title: "Project title", description: "UI, UX direction" },
        { src: technology2, title: "Project title", description: "UI, UX direction" },
        { src: technology3, title: "Project title", description: "UI, UX direction" },
        { src: technology4, title: "Project title", description: "UI, UX direction" },
      ],
    },
    {
      title: "Videography Services",
      description:
        "Create unforgettable narratives with our expert videography offerings devoted to showcasing your tale on screen. We capture every second with creativity and precision, making sure every video reflects the coronary heart brand new your emblem except photos made for numerous platforms. Whether or not promotional content material or event highlights that captivate viewers from beginning to finish, our collaborative approach ensures a smooth manner from making plans through post-manufacturing, resulting in awesome consequences with our videography offerings. Expect impactful films that now not only entertain but also inspire motion among your target audience. ",
      categories: ["Graphic Design", "UI/UX Design", "Mobile App Design", "PPT Design"],
      images: [
        { src: technology1, title: "Project title", description: "UI, UX direction" },
        { src: technology2, title: "Project title", description: "UI, UX direction" },
        { src: technology3, title: "Project title", description: "UI, UX direction" },
        { src: technology4, title: "Project title", description: "UI, UX direction" },
      ],
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="serviceAccordion">
      <div className="servicesHeader">
        <h2>Our Services</h2>
      </div>

      <div>
        {services.map((service, index) => (
          <div key={index} className="accordionItem">
            <h2
              className="accordionHeader"
              onClick={() => toggleAccordion(index)}
            >
              {service.title}
              <span className="accordionIcon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </h2>
            <div className={`accordionContent ${activeIndex === index ? "active" : ""}`}>
              <p>{service.description}</p>
              <div className="categories">
                <div className="categoryColumn">
                  {service.categories
                    .slice(0, Math.ceil(service.categories.length / 2))
                    .map((category, i) => (
                      <Link
                        key={i}
                        to={`/${category.toLowerCase().replace(/\s+/g, "-")}`}
                        className="category"
                      >
                        {category}
                      </Link>
                    ))}
                </div>
                <div className="categoryColumn">
                  {service.categories
                    .slice(Math.ceil(service.categories.length / 2))
                    .map((category, i) => (
                      <Link
                        key={i + Math.ceil(service.categories.length / 2)} // Ensure unique keys
                        to={`/${category.toLowerCase().replace(/\s+/g, "-")}`}
                        className="category"
                      >
                        {category}
                      </Link>
                    ))}
                </div>
              </div>

              <Swiper
                slidesPerView={3} // Default for larger screens
                spaceBetween={10}
                className="swiper"
                breakpoints={{
                  0: { // For very small screens
                    slidesPerView: 1,
                    spaceBetween: 5,
                  },
                  640: { // For small screens
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  1024: { // For medium to large screens
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                }}
              >
                {service.images.map((image, i) => (
                  <SwiperSlide key={i}>
                    <div className="slide">
                      <img src={image.src} alt={image.alt} />
                      <h4>{image.title}</h4>
                      <p>{image.description}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceAccordion;
