import React from "react";
import "./FollowUsOn.css";
import followuson1 from "../../Assets/Career/followuson1.png"
import followuson2 from "../../Assets/Career/followuson2.png"
import followuson3 from "../../Assets/Career/followuson3.png"
import followuson4 from "../../Assets/Career/followuson4.png"
import followuson5 from "../../Assets/Career/followuson5.png"
import { FaFacebook } from "react-icons/fa";

const FollowUsOn = () => {
  const socialLinks = [
    { name: "Facebook", icon: <FaFacebook />, link: "#" },
    { name: "Twitter", icon: <FaFacebook />, link: "#" },
    { name: "LinkedIn", icon: <FaFacebook />, link: "#" },
    { name: "Youtube", icon: <FaFacebook />, link: "#" },
    { name: "Instagram", icon: <FaFacebook />, link: "#" },
  ];

  return (
    <div className="followuson-container">
      <div className="followuson-content">
      <div className="followuson-header">
        <h2>Follow us on social to <br />see what we're up to!</h2>
        <div className="followuson-images">
          <img
            src={followuson1}
            alt="Person in suit"
            className="followuson-image"
          />
          </div>
      </div>
      
          <div className="followuson-images">
          <img
            src={followuson2}
            alt="People drinking coffee"
            className="followuson-image"
          />
          <img
            src={followuson3}
            alt="Smiling person"
            className="followuson-image"
          />
          </div>
        <div className="followuson-images">

          <img
            src={followuson4}
            alt="Logo graphic"
            className="followuson-image"
          />
        </div>

        <div className="followuson-social">
        <div className="followuson-images">

<img
  src={followuson5}
  alt="Logo graphic"
  className="followuson-image"
/>
</div>
          <p>We are eager to be in touch with you in the following channels:</p>
          <div className="followuson-social-icons">
            {socialLinks.map((social, index) => (
              <a
                key={index}
                href={social.link}
                target="_blank"
                rel="noopener noreferrer"
                className="followuson-social-link"
              >
                <i className={`fa ${social.icon}`}></i>
                {social.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowUsOn;
