import React from "react";
import "./ServicesBanner.css";
import bannerImage from "../../Assets/banners/servicesban.png";

const ServicesBanner = () => {
  return (
    <section className="services-banner">
      <img src={bannerImage} alt="Branding" className="banner-image" />
      <div className="play-button">
        <button aria-label="Play Video">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon">
            <polygon points="5 3 19 12 5 21 5 3"></polygon>
          </svg>
        </button>
      </div>
    </section>
  );
};

export default ServicesBanner;
