import React from "react";
import "./DisruptingIndustries.css"; // Create this CSS file for styling
import { IoHomeOutline } from "react-icons/io5";
import { MdOutlineAnalytics } from "react-icons/md";


// Industry data example
const industries = [
  { name: "Real Estate & Hospitality", icon: <IoHomeOutline />, highlighted: true },
  { name: "Healthcare & Wellness", icon: <MdOutlineAnalytics /> },
  { name: "Retail & Ecommerce", icon: <MdOutlineAnalytics /> },
  { name: "Media & Entertainment", icon: <MdOutlineAnalytics /> },
  { name: "Automobile & Transport", icon: <MdOutlineAnalytics /> },
  { name: "Finance & Fintech", icon: <MdOutlineAnalytics /> },
  { name: "Agritech", icon: <MdOutlineAnalytics /> },
  { name: "Edtech & Learning", icon: <MdOutlineAnalytics /> },
];

const DisruptingIndustries = () => {
  return (
    <div className="disrupting-industries-container">
      <h2>The Sectors  <span className="highlight">We’ve Served</span></h2>
      <div className="industries-grid">
        {industries.map((industry, index) => (
          <div
            key={index}
            className={`industry-card ${industry.highlighted ? "highlighted" : ""}`}
          >
            <div className="icon top-right">{industry.icon}</div>
            <div className="name bottom-left">{industry.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DisruptingIndustries;
