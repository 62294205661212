import React from 'react';
import './CareerSteps.css';

const steps = [
  {
    id: '01',
    title: 'Find a role that suits you',
    description: 'Discover open positions and find your desired one in the DigiWorq website, job listings, or social media.',
  },
  {
    id: '02',
    title: 'Send your application',
    description: 'Some simple questions should be answered, and your contact information is required.',
  },
  {
    id: '03',
    title: 'Receive your interview invite',
    description: 'We review all applications within 3 working days and send invitations to candidates.',
  },
  {
    id: '04',
    title: 'Choose an interview slot',
    description: 'You will have a friendly discussion with the CEO and your supervisor to talk about the work, life, etc.',
  },
  {
    id: '05',
    title: 'Preliminary Interview',
    description: 'Sometimes, we ask candidates to participate in a technical challenge to demonstrate their proficiency.',
  },
  {
    id: '06',
    title: 'Meet your teammates',
    description: 'It is crucial to ensure all team members feel comfortable. We value culturally diverse team members.',
  },
  {
    id: '07',
    title: 'Start a new journey!',
    description: 'Your colleagues are waiting for you to say a warm welcome.',
  },
];

const CareerSteps = () => {
  return (
    <section className="careersteps">
      <h2 className="careersteps-title">How to become a DigiWorq?</h2>
      <p className="careersteps-subtitle">
        Joining DigiWorq is Simple! Ready to take the next step? Explore our streamlined application process below.
      </p>
      <div className="careersteps-timeline">
        <div className="careersteps-line"></div>
        {steps.map((step, index) => (
          <div
            key={index}
            className={`careersteps-item ${
              index % 2 === 0 ? 'careersteps-right' : 'careersteps-left'
            }`}
          >
            {index % 2 !== 0 && (
              <div className="careersteps-content">
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            )}
            <div className="careersteps-icon-container">
              <div className="careersteps-icon">{step.id}</div>
            </div>
            {index % 2 === 0 && (
              <div className="careersteps-content">
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default CareerSteps;
