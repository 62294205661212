import React from 'react';
import './OurProcess.css'; // Add styles in a separate CSS file
import { FaFunnelDollar } from "react-icons/fa";
import { GiHornInternal } from "react-icons/gi";
import { LuMessageCircleMore } from "react-icons/lu";


const OurProcess = () => {
  const steps = [
    {
      title: 'Understanding Client Requirements',
      description: 'We begin by thoroughly assessing client needs and objectives that this aligns with your vision, mission, and goals.',
      icon: <FaFunnelDollar />, // Replace with the actual path to the icon
    },
    {
      title: 'Planning and Strategy',
      description: 'Our team develops a comprehensive marketing strategy that guides all subsequent efforts for the best outcomes.',
      icon: <GiHornInternal />, // Replace with the actual path to the icon
    },
    {
      title: 'Design Concept with UI/UX',
      description: 'We create and share innovative design concepts and prototypes that focus on creativity and better visualisation.',
      icon: <LuMessageCircleMore />
      , // Replace with the actual path to the icon
    },
    {
      title: 'Content & Development',
      description: 'Our team works simultaneously to curate the best digital assets with utmost quality and efficiency in this process.',
      icon: <LuMessageCircleMore />
      , // Replace with the actual path to the icon
    },
    {
      title: 'Testing and Quality Assurance (QA)',
      description: 'We conduct a lot of tests to identify & resolve any issues before launch to meet our high standards for good usability.',
      icon: <LuMessageCircleMore />
      , // Replace with the actual path to the icon
    },
    {
      title: 'Launch',
      description: 'Finally, we execute the launch to introduce your brand to the market effectively with maximum visibility and engagement.',
      icon: <LuMessageCircleMore />
      , // Replace with the actual path to the icon
    },
    {
      title: 'Post Launch Maintenance (if needed)',
      description: 'We also provide maintenance and support to ensure your assets remain updated, secure, and optimal in performance.',
      icon: <LuMessageCircleMore />
      , // Replace with the actual path to the icon
    },
  ];

  return (
    <div className="ourprocess-container">
      <div className="ourprocess-header">
        <h2>
          Our <span className="ourprocess-highlight">Process</span>
        </h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
      <div className="ourprocess-cards">
        {steps.map((step, index) => (
          <div className="ourprocess-card" key={index}>
            <div className="ourprocess-icon">{step.icon}</div>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
      <div className="ourprocess-button-container">
        <button className="ourprocess-button">Learn more</button>
      </div>
    </div>
  );
};

export default OurProcess;
